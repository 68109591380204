import API from '..';
import { sales } from '../entpoints';

/* eslint-disable no-useless-catch */
export const updateGoldRate = async (
  margin_24k: string,
  margin_22k: string,
  rate_17_21k: string,
  rate_14_17k: string
) => {
  try {
    const data = {
      margin_24k,
      margin_22k,
      rate_17_21k,
      rate_14_17k,
    };
    const resp = await API.post(sales.createGoldRate, data);
    return resp;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getAllUpdates = async (page_size?: number | null, page_number?: number | null) => {
  try {
    const resp = await API.get(
      `${sales.getUpdateHistory}?page_size=${page_size}&page_number=${page_number}`
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

export const getLiveRates = async () => {
  try {
    const resp = await API.get(`${sales.getLiveGoldRate}`);
    return resp;
  } catch (error) {
    throw error;
  }
};

export const getPledgeEstimationsCount = async () => {
  try {
    const resp = await API.get(sales.getPledgeEstimationCount);
    return resp;
  } catch (error) {
    throw error;
  }
};

export const getNegotiationApprovals = async (
  status: string | null,
  pageSize: number = 10,
  page: number = 1,
  startDate: string = '',
  endDate: string = '',
  searchText: string = '',
  transactionType: string = ''
) => {
  try {
    const resp = await API.get(
      `${sales.getNegotiationApprovals}?status=${status}&start_date=${startDate}&end_date=${endDate}&page_size=${pageSize}&page_number=${page}&search_text=${searchText}&transaction_type=${transactionType}`
    );
    return resp;
  } catch (error) {
    throw new Error(error.response?.data?.message);
  }
};

export const getServiceChargeApprovals = async (
  status: string | null,
  pageSize: number = 10,
  page: number = 1,
  startDate: string = '',
  endDate: string = '',
  searchText: string = '',
  transactionType: string = ''
) => {
  try {
    const resp = await API.get(
      `${sales.getServiceChargeApprovals}?status=${status}&start_date=${startDate}&end_date=${endDate}&page_size=${pageSize}&page_number=${page}&search_text=${searchText}&transaction_type=${transactionType}`
    );
    return resp;
  } catch (error) {
    throw new Error(error.response?.data?.message);
  }
};

export const getGoldRateApprovals = async (
  status: string | null,
  pageSize: number = 10,
  page: number = 1,
  startDate: string = '',
  endDate: string = '',
  searchText: string = '',
  transactionType: string = ''
) => {
  try {
    const resp = await API.get(
      `${sales.getGoldRateApprovals}?status=${status}&start_date=${startDate}&end_date=${endDate}&page_size=${pageSize}&page_number=${page}&search_text=${searchText}&transaction_type=${transactionType}`
    );
    return resp;
  } catch (error) {
    throw new Error(error.response?.data?.message);
  }
};

export const getReleaseApprovals = async (
  status: string | null,
  pageSize: number = 10,
  page: number = 1,
  startDate: string = '',
  endDate: string = '',
  searchText: string = '',
  transactionType: string = ''
) => {
  try {
    const resp = await API.get(
      `${sales.getPledgeEstimations}?status=${status}&start_date=${startDate}&end_date=${endDate}&page_size=${pageSize}&page_number=${page}&search_text=${searchText}`
    );
    return resp;
  } catch (error) {
    throw new Error(error.response?.data?.message);
  }
};

export const getPaymentApprovals = async (
  status: string | null,
  pageSize: number = 10,
  page: number = 1,
  startDate: string = '',
  endDate: string = '',
  searchText: string = '',
  transactionType: string = ''
) => {
  try {
    const resp = await API.get(
      `${sales.paymentApprovals}?status=${status}&start_date=${startDate}&end_date=${endDate}&page_size=${pageSize}&page_number=${page}&search_text=${searchText}`
    );
    return resp;
  } catch (error) {
    throw new Error(error.response?.data?.message);
  }
};

export const handleSalesApproval = async (
  remark: string | null,
  approved: boolean,
  transaction_id: string
) => {
  try {
    const payload = {
      remark,
      approved,
      transaction_id,
    };
    const resp = await API.post(sales.salesApproval, payload);
    return resp;
  } catch (error) {
    throw new Error(error.response?.data?.message);
  }
};

export const getReleaseApprovalsCount = async () => {
  try {
    const resp = await API.get(sales.getPaymentApprovalsCount);
    return resp;
  } catch (error) {
    throw error;
  }
};

export const getNegotiationsCount = async () => {
  try {
    const resp = await API.get(sales.getNegotiationApprovalsCount);
    return resp;
  } catch (error) {
    throw error;
  }
};
export const getServiceChargeApprovalCount = async () => {
  try {
    const resp = await API.get(sales.getServiceChargeApprovalsCount);
    return resp;
  } catch (error) {
    throw error;
  }
};

export const getGoldRateApprovalsCount = async () => {
  try {
    const resp = await API.get(sales.getGoldRateApprovalsCount);
    return resp;
  } catch (error) {
    throw error;
  }
};

export const handleReleasePaymentApproval = async (
  remark: string | null,
  approved: boolean,
  transaction_id: string
) => {
  try {
    const payload = {
      remark,
      approved,
      transaction_id,
    };
    const resp = await API.post(sales.releasePaymentApproval, payload);
    return resp;
  } catch (error) {
    throw new Error(error.response?.data?.message);
  }
};
