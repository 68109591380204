import API from '..';
import { orders } from '../entpoints';

export const getAllOrders = async ({
  status,
  pageSize = 10,
  page = 1,
  startDate = '',
  endDate = '',
  searchText = '',
  transactionType = '',
}: {
  status: any;
  pageSize?: number;
  page?: number;
  startDate?: string;
  endDate?: string;
  searchText?: string;
  transactionType?: string;
}) => {
  try {
    const resp = await API.get(
      `${orders.getAllOrders}?status=${status}&page_size=${pageSize}&page_number=${page}&start_date=${startDate}&end_date=${endDate}&search_text=${searchText}&transaction_type=${transactionType}`
    );
    return resp;
  } catch (error) {
    throw new Error(error.response?.data?.message);
  }
};

export const getOrdersCounts = async () => {
  try {
    const resp = await API.get(orders.getOrdersCount);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
export const updateOrder = async (
  id: string,
  payload: { gross_weight: number; received: boolean; difference: number }
) => {
  const response = await API.patch(`${orders.getAllOrders}/${id}`, payload);
  return response;
};

export const getOrdersReport = async (startDate: string, endDate: string, status: string) => {
  try {
    const resp = await API.get(
      `${orders.getOrderSum}?start_date=${startDate}&end_date=${endDate}&status=${status}`
    );
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
