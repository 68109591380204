import * as Yup from 'yup';

export const validateAlternateMobile = (value: string | undefined | null) => {
  if (!value) return true; // Skip validation if the value is undefined or empty
  const regex = /^[1-9][0-9]{9}$/;
  return regex.test(value);
};
export const numberBetween0And3 = Yup.number()
  .typeError('Service charge is required')
  .max(3, 'Service charge cannot be more than 3%')
  .min(0, 'Service charge cannot be less than 0%')
  .required('Service charge is required')
  .test(
    'is-valid-number',
    'Service charge must be a valid number between 0 and 3 with at most two decimal places',
    (value) => {
      if (value === undefined || value === null) return true;
      const valueString = value.toString();
      const regex = /^\d+(\.\d{1,2})?$/;
      return (
        regex.test(valueString) &&
        parseFloat(valueString) >= 0 &&
        parseFloat(valueString) <= 3
      );
    }
  );
