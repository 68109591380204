import { useEffect } from 'react';

const useScrollToError = (errors: any) => {
  useEffect(() => {
    if (errors) {
      const errorElements: HTMLElement[] = [];
      const getErrorElements = (obj: any, parentKey = '') => {
        Object.entries(obj).forEach(([key, value]) => {
          const currentKey = parentKey ? `${parentKey}.${key}` : key;
          if (value && typeof value === 'object' && !('message' in value)) {
            getErrorElements(value, currentKey);
          } else {
            // Target both input elements and elements with the data-error attribute
            const element = document.getElementsByName(currentKey)[0] as HTMLElement ||
                            document.querySelector(`[data-error="${currentKey}"]`);
            if (element) errorElements.push(element);
          }
        });
      };

      getErrorElements(errors);
      if (errorElements.length) {
        // Sort to bring the tallest element first, which typically improves UX
        errorElements.sort((a, b) => b.scrollHeight - a.scrollHeight);
        errorElements[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [errors]);
};

export default useScrollToError;
