import API from '..';
import { notifications } from '../entpoints';

export const fetchAllNotifications = async (page: number = 1) => {
  try {
    const resp = await API.get(`${notifications.getAllNotifications}?page_number=${page}`);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const fetchUnreadNotifications = async (page: number = 1) => {
  try {
    const resp = await API.get(`${notifications.getunReadNotifications}&page_number=${page}`);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const fetchNotificationsCount = async () => {
  try {
    const resp = await API.get(notifications.getnotificationsCount);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const readNotification = async (notificationId: string) => {
  try {
    const resp = await API.patch(`${notifications.markNotificationsAsRead}/${notificationId}`, {
      read: true,
    });
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
