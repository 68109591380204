import { BidData } from "src/interfaces";

import API from '..';
import { bids } from "../entpoints";

export const createBidDetails = async ( formData: BidData) => {
  try {
    const resp = await API.post(`${bids.createBid}`, {
      ...formData,
      bidder_id: formData.bidder_id?.value,
    });
    return resp;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

export const updateBidDetails = async (bidId: string, formData: any) => {
  try {
    const resp = await API.patch(`${bids.updateBid}/${bidId}`, {
      ...formData,
      bidder_id: formData.bidder_id?.value,
    });
    return resp;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

export const deleteBidDetails = async (bidId: string) => {
  try {
    const resp = await API.delete(`${bids.deleteBid}/${bidId}`);
    return resp;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

export const getBids = async (
  status: string | undefined,
  pageSize: number = 10,
  page: number = 1,
  startDate: string = '',
  endDate: string = '',
  searchText: string = '',
  transactionType: string = ''
) => {
  try {
    const resp = await API.get(
      `${bids.fetchBids}?status=${status}&page_size=${pageSize}&page_number=${page}&start_date=${startDate}&end_date=${endDate}&search_text=${searchText}&transaction_type=${transactionType}`
    );
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getBidsSum = async (
  startDate: string = '',
  endDate: string = '',
) => {
  try {
    const resp = await API.get(
      `${bids.fetchBidsSum}?start_date=${startDate}&end_date=${endDate}`
    );
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}