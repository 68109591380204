import { BidData } from "src/interfaces";

import API from '..';
import { bidder } from "../entpoints";

export const createBidderDetails = async ( formData: BidData) => {
  try {
    const resp = await API.post(`${bidder.createBidder}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return resp;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

export const getbidder = async () => {
    try {
      const resp = await API.get(`${bidder.fetchBidders}`);
      return resp;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };