import { m } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import React, { useRef, useState, useEffect, useCallback } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import { Skeleton } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { RootState, AppDispatch } from 'src/store/store';
import {
  getAllNotifications,
  getUnreadNotifications,
  markNotificationsAsRead,
} from 'src/store/notifications-slice';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';

/* eslint-disable */
import NotificationItem from './notification-item';

// ----------------------------------------------------------------------

const TABS = [
  { value: 'all', label: 'All' },
  { value: 'unread', label: 'Unread' },
];

const NotificationLoader = ({
  rows = 2,
  withDivider = true,
}: {
  rows?: number;
  withDivider?: boolean;
}) => (
  <Stack p={2} gap={2}>
    {Array.from({ length: rows }).map((_, index) => (
      <React.Fragment key={index}>
        <Skeleton width="50%" />
        <Skeleton />
        {withDivider && index < rows - 1 && <Divider />}
      </React.Fragment>
    ))}
  </Stack>
);

// Render nnotifications
const RenderNotifications = ({
  type,
  page,
  setPage,
  onNotificationClick,
}: {
  type: 'all' | 'unread';
  page: number;
  onNotificationClick: (val: string) => void; // Added type for click handler
  setPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [notifications, setNotifications] = useState<any[]>([]);
  const observer = useRef<IntersectionObserver | null>(null);
  const scrollRef = useRef<HTMLDivElement | null>(null);

  // store
  const dispatch = useDispatch<AppDispatch>();
  const { allNotificationsRecords, unreadNotificationsRecords, loading, notificationsObject } =
    useSelector((store: RootState) => store.notifications);
  const notificationsFromStore = useSelector((store: RootState) =>
    type === 'all' ? store.notifications.allNotifications : store.notifications.unreadNotifications
  );

  // get the last element

  const lastNotificationRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading || !node) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          ((type === 'all' && page < allNotificationsRecords) ||
            (type === 'unread' && page < unreadNotificationsRecords))
        ) {
          setPage((prev) => prev + 1); // Increment page to load more notifications
        }
      });
      observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, allNotificationsRecords, unreadNotificationsRecords]
  );

  // fetch notifications when tab/page changes
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        if (type === 'all') {
          await dispatch(getAllNotifications({ page }));
        } else {
          await dispatch(getUnreadNotifications({ page }));
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, page]);

  // reset scroll on tab switch
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  }, [type]);

  useEffect(() => {
    setNotifications(notificationsFromStore);
  }, [notificationsFromStore]);

  return (
    <Scrollbar ref={scrollRef}>
      <List disablePadding>
        {loading && notifications.length < 1 ? (
          <NotificationLoader rows={10} />
        ) : notifications.length > 0 ? (
          <>
            {notifications.map((item, index) => {
              if (notifications.length === index + 1) {
                return (
                  <NotificationItem
                    onClick={onNotificationClick}
                    ref={lastNotificationRef}
                    key={item.id}
                    notification={notificationsObject[item]}
                  />
                );
              }
              return (
                <NotificationItem
                  onClick={onNotificationClick}
                  key={item.id}
                  notification={notificationsObject[item]}
                />
              );
            })}
            {loading ? <NotificationLoader /> : null}
          </>
        ) : (
          <Typography alignSelf="center" variant="body2" sx={{ p: 2, textAlign: 'center' }}>
            No notifications available
          </Typography>
        )}
      </List>
    </Scrollbar>
  );
};

// Main render function ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const drawer = useBoolean();
  const smUp = useResponsive('up', 'sm');
  const { allNotificationsCount, unreadNotifcationsCount } = useSelector(
    (store: RootState) => store.notifications
  );
  const dispatch = useDispatch<AppDispatch>();
  const [currentTab, setCurrentTab] = useState<'all' | 'unread'>('all');
  const [page, setPage] = useState(1);

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: any) => {
    setPage(1);
    setCurrentTab(newValue);
    // Reset page when tab changes
  }, []);

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>

      {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );

  const handleNotificationClick = useCallback(
    async (id: string) => {
      try {
        const resp = await dispatch(markNotificationsAsRead({ id }));
        drawer.onFalse();
        return resp;
      } catch (error) {
        console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [drawer]
  );

  const renderTabs = (
    <Tabs value={currentTab} onChange={handleChangeTab}>
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          iconPosition="end"
          value={tab.value}
          label={tab.label}
          icon={
            <Label
              variant={tab.value === currentTab || tab.value === 'all' ? 'filled' : 'soft'}
              color={tab.value === 'unread' ? 'info' : 'default'}
            >
              {tab.value === 'unread' ? unreadNotifcationsCount : allNotificationsCount}
            </Label>
          }
          sx={{
            '&:not(:last-of-type)': {
              mr: 3,
            },
          }}
        />
      ))}
    </Tabs>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={unreadNotifcationsCount} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={25} color="white" />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        <Divider />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2.5, pr: 1 }}
        >
          {renderTabs}
        </Stack>

        <Divider />
        <RenderNotifications
          onNotificationClick={handleNotificationClick}
          type={currentTab}
          page={page}
          setPage={setPage}
        />
      </Drawer>
    </>
  );
}
