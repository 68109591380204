export const goldKarats = [
  { value: 24, label: '24k' },
  { value: 23, label: '23k' },
  { value: 22.1, label: '22k (HM)' },
  { value: 22, label: '22k (Non HM)' },
  { value: 21, label: '21k' },
  { value: 20, label: '20k' },
  { value: 19, label: '19k' },
  { value: 18, label: '18k' },
  { value: 17, label: '17k' },
  { value: 16, label: '16k' },
  { value: 15, label: '15k' },
  { value: 14, label: '14k' },
];
