import { createSlice } from '@reduxjs/toolkit';

interface AlertState {
  title: string | null;
  message: string | null;
  type: 'error' | 'success' | 'info' | 'warning' | undefined;
  isAlertActive: boolean;
  status: string;
}

const initialState: AlertState = {
  title: null,
  message: null,
  type: undefined,
  isAlertActive: false,
  status: '',
};

const alertsSlice = createSlice({
  name: 'Alerts',
  initialState,
  reducers: {
    setAlert(state, action) {
      state.title = action.payload.title;
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.isAlertActive = true;
      state.status = action.payload.status;
    },
    resetAlert(state) {
      state.title = null;
      state.message = null;
      state.type = undefined;
      state.isAlertActive = false;
      state.status = '';
    },
  },
});

export const { setAlert, resetAlert } = alertsSlice.actions;

export default alertsSlice.reducer;
