import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { MeltingSummaryTransaction } from 'src/interfaces';
import {
  getMeltingCount,
  getMeltingRecords,
  getMeltingReports,
  getMeltingSummary,
  updateMeltingRecord,
  getMeltingSummaryById,
  upadteMeltingSummaryById,
} from 'src/apis/meltings';

type TransactionCount = {
  [key: string]: number;
};

interface MeltingState {
  loading: boolean;
  records: MeltingSummaryTransaction[];
  currentPage: number;
  currentPageSize: number;
  totalRecords: number;
  error?: string;
  currentTransaction: MeltingSummaryTransaction | null;
  transactionCount: TransactionCount;
  totalGrossWeight: number;
  totalNetWeight: number;
  totalStoneWeight: number;
  totalWastage: number;
  totalBillingPurity: number;
}

// Initial State
const initialState: MeltingState = {
  loading: false,
  records: [],
  currentPage: 1,
  currentPageSize: 10,
  totalRecords: 0,
  error: undefined,
  currentTransaction: null,
  transactionCount: {
    ALL: 0,
    PENDING: 0,
    MELTED: 0,
    NOT_MELTED: 0,
  },
  totalGrossWeight: 0,
  totalNetWeight: 0,
  totalStoneWeight: 0,
  totalWastage: 0,
  totalBillingPurity: 0,
};

// Async Thunks
export const fetchMeltingReports = createAsyncThunk(
  'melting/fetchMeltingReports',
  async ({
    status,
    startDate,
    endDate,
  }: {
    status: string;
    startDate?: string;
    endDate?: string;
  }) => {
    const response = await getMeltingReports(startDate ?? '', endDate ?? '', status);
    return response;
  }
);

export const fetchMeltingCounts = createAsyncThunk('melting/fetchMeltingCounts', async () => {
  const response = await getMeltingCount();
  return response;
});

export const fetchMeltingRecords = createAsyncThunk(
  'melting/fetchMeltingRecords',
  async ({
    status,
    pageSize,
    page,
    startDate,
    endDate,
    searchText,
  }: {
    status: string;
    pageSize: number;
    page: number;
    startDate?: string;
    endDate?: string;
    searchText?: string;
  }) => {
    const response = await getMeltingRecords(
      status,
      pageSize,
      page,
      startDate,
      endDate,
      searchText
    );
    return response;
  }
);
export const updateAMeltingRecord = createAsyncThunk(
  'melting/updateMeltingRecord',
  async ({ data, currentStatus }: { data: any; currentStatus: string }) => {
    const response = await updateMeltingRecord(data);
    return { response, currentStatus };
  }
);
export const fetchMeltingSummary = createAsyncThunk(
  'melting/fetchMeltingSummary',
  async ({
    pageSize,
    page,
    startDate,
    endDate,
    searchText,
  }: {
    pageSize: number;
    page: number;
    startDate?: string;
    endDate?: string;
    searchText?: string;
  }) => {
    const response = await getMeltingSummary(pageSize, page, startDate, endDate, searchText);
    return response;
  }
);

export const fetchMeltingSummaryRecord = createAsyncThunk(
  'melting/fetchMeltingSummaryRecord',
  async (id: string) => {
    const response = await getMeltingSummaryById(id);
    return response;
  }
);

export const updateMeltingSummary = createAsyncThunk(
  'melting/updateMeltingSummary',
  async ({ id, data }: { id: string; data: any }) => {
    const response = await upadteMeltingSummaryById(id, data);
    return response;
  }
);

// Slice
const meltingSlice = createSlice({
  name: 'melting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMeltingCounts.fulfilled, (state, action) => {
      state.transactionCount = action.payload;
    });

    builder.addCase(fetchMeltingRecords.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchMeltingRecords.fulfilled, (state, action) => {
      state.loading = false;
      state.records = action.payload.records;
      state.totalRecords = action.payload.total_records;
      state.currentPage = action.payload.current_page;
      state.currentPageSize = action.payload.page_size;
    });

    builder.addCase(fetchMeltingRecords.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchMeltingReports.fulfilled, (state, action) => {
      state.totalGrossWeight = action.payload.branch_gross_weight;
      state.totalBillingPurity = action.payload.billing_purity;
      state.totalNetWeight = action.payload.branch_net_weight;
      state.totalStoneWeight = action.payload.branch_stone_weight;
      state.totalWastage = action.payload.branch_wastage;
    });

    builder.addCase(fetchMeltingSummary.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchMeltingSummary.fulfilled, (state, action) => {
      state.loading = false;
      state.records = action.payload.records;
      state.totalRecords = action.payload.total_records;
      state.currentPage = action.payload.current_page;
      state.currentPageSize = action.payload.page_size;
    });

    builder.addCase(fetchMeltingSummary.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchMeltingSummaryRecord.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchMeltingSummaryRecord.fulfilled, (state, action) => {
      state.loading = false;
      state.currentTransaction = action.payload;
    });

    builder.addCase(fetchMeltingSummaryRecord.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(updateMeltingSummary.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateMeltingSummary.fulfilled, (state, action) => {
      state.loading = false;
      const updatedRecord = action.payload;
      const index = state.records.findIndex((record) => record.id === updatedRecord.id);
      if (index !== -1) {
        state.records[index] = updatedRecord;
      }
    });

    builder.addCase(updateMeltingSummary.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default meltingSlice.reducer;
