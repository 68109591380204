// @ts-nocheck
import { GenderType, TransactionType, MaritalStatusType } from 'src/interfaces';

export const goldAssayerStatus = {
  VALUATION_PENDING: {
    value: 'VALUATION_PENDING',
    label: 'Valuation Pending',
    alertMessage:
      'Your transaction is currently being reviewed. Please check back once it has been approved by the external team.',
    stepperIndex: 1,
  },
};

export const salesStatus = {
  SALES_APPROVAL_PENDING: {
    value: 'SALES_APPROVAL_PENDING',
    label: 'Service Charge - Pending',
  },
  SALES_NEGOTIATION_PENDING: {
    value: 'SALES_NEGOTIATION_PENDING',
    label: 'Sales Negotiation - Pending',
  },
};

export const salesHeadStatus = {
  SALES__HEAD_APPROVAL_PENDING: {
    value: 'SALES_HEAD_APPROVAL_PENDING',
    label: 'Sales Head - Pending',
  },
};

export const releaseSalesHeadStatus = {
  ALL: {
    value: 'ALL',
    label: 'All',
  },

  PENDING: {
    value: 'PENDING',
    label: 'Pending',
  },

  APPROVED: {
    value: 'APPROVED',
    label: 'Approved',
  },
  REJECTED: {
    value: 'REJECTED',
    label: 'Rejected',
  },
};

export const transactionType: TransactionType = {
  PHYSICAL_GOLD: 'Physical',
  RELEASED_GOLD: 'Release',
};

export const genderType: GenderType = {
  MALE: 'Male',
  FEMALE: 'Female',
  OTHERS: 'Others',
};

export const maritalStatusType: MaritalStatusType = {
  Unmarried: 'Unmarried',
  Married: 'Married',
  Divorced: 'Divorced',
};

export const addressProofTypes: DocumentType = {
  AADHAAR: 'Aadhaar',
  PASSPORT: 'Passport',
  PAN: 'PAN',
  DRIVING_LICENSE: 'Driving Licence',
  VOTER_ID: 'Voter Id',
};

export const occupationType = {
  Salaried: 'Salaried',
  Business: 'Business',
  Retired: 'Retired/Service',
  Unemployed: 'Unemployed',
  Student: 'Student',
};

export const motherTongue = {
  Kannada: 'Kannada',
  Hindi: 'Hindi',
  English: 'English',
  Telugu: 'Telugu',
  Malayalam: 'Malayalam',
  Tamil: 'Tamil',
  Urdu: 'Urdu',
  Assamese: 'Assamese',
  Odia: 'Odia',
  Tulu: 'Tulu',
  Marathi: 'Marathi',
  Bengali: 'Bengali',
  Gujarati: 'Gujarati',
};

export const Salary = {
  '1_5L': '1L-5L',
  '5L_10L': '5L-10L',
  above_10L: 'Above 10L',
};

export const orgType = {
  'Govt sector': 'Govt sector',
  'Pvt sector': 'Pvt sector',
};

export const bankBranch = {
  'J C Nagar': 'J C Nagar',
  'R T Nagar': 'R T Nagar',
};

export const reasonForSelling = [
  { label: 'Old gold', value: 'Old gold' },
  { label: 'Personal reason', value: 'Personal reason' },
  { label: 'Loan', value: 'Loan' },
  { label: 'Financial problem', value: 'Financial problem' },
  { label: 'Medical emergencies', value: 'Medical emergencies' },
  { label: 'Education', value: 'Education' },
  { label: 'Broken Jewellery', value: 'Broken Jewellery' },
  { label: 'Property purchase', value: 'Property purchase' },
  { label: 'House construction / Renovation', value: 'House construction / Renovation' },
  { label: 'Marriage', value: 'Marriage' },
  { label: 'Buy Vehicle', value: 'Buy Vehicle' },
  { label: 'Rent home', value: 'Rent home' },
  { label: 'Gold Value Trading', value: 'Gold Value Trading' },
  { label: 'Business', value: 'Business' },
  { label: 'Other expenses', value: 'Other expenses' },
];

export const bankName = [
  { label: 'Abhyudaya Cooperative Bank Limited', value: 'Abhyudaya Cooperative Bank Limited' },
  { label: 'Abu Dhabi Commercial Bank', value: 'Abu Dhabi Commercial Bank' },
  { label: 'Allahabad Bank', value: 'Allahabad Bank' },
  { label: 'Andhra Bank', value: 'Andhra Bank' },
  { label: 'Axis Bank', value: 'Axis Bank' },
  { label: 'B N P Paribas', value: 'B N P Paribas' },
  { label: 'Bandhan Bank Limited', value: 'Bandhan Bank Limited' },
  { label: 'Bank Of America', value: 'Bank Of America' },
  { label: 'Bank Of Baroda', value: 'Bank Of Baroda' },
  { label: 'Bank Of India', value: 'Bank Of India' },
  { label: 'Bank Of Maharashtra', value: 'Bank Of Maharashtra' },
  { label: 'Bank Of Tokyo Mitsubishi Limited', value: 'Bank Of Tokyo Mitsubishi Limited' },
  {
    label: 'Bharat Cooperative Bank Mumbai Limited',
    value: 'Bharat Cooperative Bank Mumbai Limited',
  },
  { label: 'Bharatiya Mahila Bank Limited', value: 'Bharatiya Mahila Bank Limited' },
  { label: 'Canara Bank', value: 'Canara Bank' },
  { label: 'Catholic Syrian Bank Limited', value: 'Catholic Syrian Bank Limited' },
  { label: 'Central Bank Of India', value: 'Central Bank Of India' },
  { label: 'Citi Bank', value: 'Citi Bank' },
  { label: 'City Union Bank Limited', value: 'City Union Bank Limited' },
  { label: 'Corporation Bank', value: 'Corporation Bank' },
  {
    label: 'Credit Agricole Corporate And Investment Bank Calyon Bank',
    value: 'Credit Agricole Corporate And Investment Bank Calyon Bank',
  },
  { label: 'Dcb Bank Limited', value: 'Dcb Bank Limited' },
  { label: 'Dena Bank', value: 'Dena Bank' },
  { label: 'Deustche Bank', value: 'Deustche Bank' },
  { label: 'Development Bank Of Singapore', value: 'Development Bank Of Singapore' },
  { label: 'Dhanalakshmi Bank', value: 'Dhanalakshmi Bank' },
  { label: 'Federal Bank', value: 'Federal Bank' },
  { label: 'Hdfc Bank', value: 'Hdfc Bank' },
  { label: 'Hsbc Bank', value: 'Hsbc Bank' },
  { label: 'Icici Bank Limited', value: 'Icici Bank Limited' },
  { label: 'Idbi Bank', value: 'Idbi Bank' },
  { label: 'Indian Bank', value: 'Indian Bank' },
  { label: 'Indian Overseas Bank', value: 'Indian Overseas Bank' },
  { label: 'Indusind Bank', value: 'Indusind Bank' },
  { label: 'Ing Vysya Bank', value: 'Ing Vysya Bank' },
  { label: 'Jammu And Kashmir Bank Limited', value: 'Jammu And Kashmir Bank Limited' },
  {
    label: 'Kallappanna Awade Ichalkaranji Janata Sahakari Bank Limited',
    value: 'Kallappanna Awade Ichalkaranji Janata Sahakari Bank Limited',
  },
  { label: 'Karnataka Bank Limited', value: 'Karnataka Bank Limited' },
  { label: 'Karnataka Vikas Grameena Bank', value: 'Karnataka Vikas Grameena Bank' },
  { label: 'Karur Vysya Bank', value: 'Karur Vysya Bank' },
  { label: 'Kotak Mahindra Bank Limited', value: 'Kotak Mahindra Bank Limited' },
  { label: 'Laxmi Vilas Bank', value: 'Laxmi Vilas Bank' },
  { label: 'Mizuho Corporate Bank Limited', value: 'Mizuho Corporate Bank Limited' },
  { label: 'Nkgsb Cooperative Bank Limited', value: 'Nkgsb Cooperative Bank Limited' },
  { label: 'Oriental Bank Of Commerce', value: 'Oriental Bank Of Commerce' },
  { label: 'Pragathi Krishna Gramin Bank', value: 'Pragathi Krishna Gramin Bank' },
  {
    label: 'Punjab And Maharshtara Cooperative Bank',
    value: 'Punjab And Maharshtara Cooperative Bank',
  },
  { label: 'Punjab And Sind Bank', value: 'Punjab And Sind Bank' },
  { label: 'Punjab National Bank', value: 'Punjab National Bank' },
  { label: 'Ratnakar Bank Limited', value: 'Ratnakar Bank Limited' },
  { label: 'Reserve Bank Of India', value: 'Reserve Bank Of India' },
  { label: 'Saraswat Cooperative Bank Limited', value: 'Saraswat Cooperative Bank Limited' },
  { label: 'South Indian Bank', value: 'South Indian Bank' },
  { label: 'Standard Chartered Bank', value: 'Standard Chartered Bank' },
  { label: 'State Bank Of Bikaner And Jaipur', value: 'State Bank Of Bikaner And Jaipur' },
  { label: 'State Bank Of Hyderabad', value: 'State Bank Of Hyderabad' },
  { label: 'State Bank Of India', value: 'State Bank Of India' },
  { label: 'State Bank Of Mysore', value: 'State Bank Of Mysore' },
  { label: 'State Bank Of Patiala', value: 'State Bank Of Patiala' },
  { label: 'State Bank Of Travancore', value: 'State Bank Of Travancore' },
  { label: 'Syndicate Bank', value: 'Syndicate Bank' },
  { label: 'Tamilnad Mercantile Bank Limited', value: 'Tamilnad Mercantile Bank Limited' },
  { label: 'The Cosmos Co Operative Bank Limited', value: 'The Cosmos Co Operative Bank Limited' },
  {
    label: 'The Karanataka State Cooperative Apex Bank Limited',
    value: 'The Karanataka State Cooperative Apex Bank Limited',
  },
  { label: 'The Royal Bank Of Scotland N V', value: 'The Royal Bank Of Scotland N V' },
  { label: 'The Shamrao Vithal Cooperative Bank', value: 'The Shamrao Vithal Cooperative Bank' },
  {
    label: 'The Vishweshwar Sahakari Bank Limited',
    value: 'The Vishweshwar Sahakari Bank Limited',
  },
  { label: 'Tjsb Sahakari Bank Limited', value: 'Tjsb Sahakari Bank Limited' },
  { label: 'Tjsb Sahakari Bank Ltd', value: 'Tjsb Sahakari Bank Ltd' },
  {
    label: 'Tumkur Grain Merchants Cooperative Bank Limited',
    value: 'Tumkur Grain Merchants Cooperative Bank Limited',
  },
  { label: 'Uco Bank', value: 'Uco Bank' },
  { label: 'Union Bank Of India', value: 'Union Bank Of India' },
  { label: 'United Bank Of India', value: 'United Bank Of India' },
  { label: 'Vijaya Bank', value: 'Vijaya Bank' },
  { label: 'Yes Bank', value: 'Yes Bank' },
];
