/* eslint-disable */

import * as yup from 'yup';
import toast from 'react-hot-toast';
import { makeStyles } from '@material-ui/styles';
import React, { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import { Box, Grid, Stack, Divider, Skeleton, TextField, Typography,  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@mui/material';

import { useRouter } from 'src/routes/hooks/use-router';

import { requestDocuments, getKycPendingDocs } from 'src/apis/kyc';

import CustomModal from 'src/components/modal';
import ReactAsyncSelect from 'src/components/async-select';
import CustomBreadcrumbs from 'src/components/custom-breadcrumb/custom-breadcrumb';
import { requestDocsSchema } from 'src/sections/components/schema/request-docs-schema';

interface RequestDocsModalProps {
  open: boolean;
  onClose: () => void;
  kycId: string;
}
interface FormValues {
  documents: { label: string; value: string }[];
  remarks: string;
}

export const RenderHeader: React.FC<{ heading: string; links: any; action?: React.ReactNode }> = ({
  heading,
  links,
  action,
}) => (
  <CustomBreadcrumbs
    heading={heading}
    links={links}
    action={action}
    sx={{ mb: { xs: 0, md: 0 } }}
    fixed={true}
  />
);

export const DynamicDetails: React.FC<{ title: string; details: { [key: string]: string } }> = ({
  title,
  details,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (Object.keys(details).length > 0) {
      setLoading(false);
    }
  }, [details]);

  return (
    <Box className={classes.container}>
  <Box className={classes.titleContainer}>
    <Typography variant="h5" className={classes.title}>
      {title}
    </Typography>
  </Box>
  <Divider className={classes.divider} />
  <Grid container spacing={1} className={classes.customerGrid}>
    {loading ? (
      Array.from(new Array(6)).map((_, index) => (
        <React.Fragment key={index}>
          <Grid item>
            <Skeleton variant="text" width={120} />
          </Grid>
          <Grid item>
            <Skeleton variant="text" width={200} />
          </Grid>
        </React.Fragment>
      ))
    ) : (
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {Object.entries(details).map(([key, value], index) => (
                <TableRow key={index}>
                  <TableCell className={classes.customerText}>{key}</TableCell>
                  <TableCell className={classes.customerDetails}>{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    )}
  </Grid>
</Box>
  );
};

export const RequestDocsModal: React.FC<RequestDocsModalProps> = ({ open, onClose, kycId }) => {
  const [loading, setLoading] = useState(false);
  const [documentOptions, setDocumentOptions] = useState<{ label: string; value: string }[]>([]);
  const [remarksError, setRemarksError] = useState<string>('');
  const classes = useStyles();
  const router = useRouter();

  const methods = useForm<FormValues>({
    resolver: yupResolver(requestDocsSchema) as any,
    defaultValues: {
      documents: [],
      remarks: '',
    },
  });

  const {
    setValue,
    register,
    handleSubmit,
    reset,
  } = methods;

  useEffect(() => {
    if (open) {
      const fetchPendingDocs = async () => {
        try {
          const resp = await getKycPendingDocs(kycId);
          setDocumentOptions(resp.map((doc: any) => ({ label: doc.label, value: doc.value })));
        } catch (error) {
          console.log(error);
        }
      };
      fetchPendingDocs();
      reset();
    }
  }, [open, kycId, reset, setValue]);

  const handleRequestDocs = async (data: any) => {
    setLoading(true);
    try {
      const documentValues = data.documents.map(
        (doc: { label: string; value: string }) => doc.value
      );
      const payload = {
        documents: documentValues,
        remark: data.remarks,
      };
      await requestDocuments(kycId, payload);
      toast.success('Requested additional documents');
      router.back();
      setLoading(false);
      onClose();
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  const handleClose = () => {
    onClose();
    reset();
  };
  return (
    <FormProvider {...methods}>
      <CustomModal
        title="Request Additional Information"
        onSubmit={handleSubmit(handleRequestDocs)}
        buttons={
          <Stack direction="row" spacing={3}>
            <LoadingButton variant="outlined" onClick={handleClose}>
              Cancel
            </LoadingButton>
            <LoadingButton loading={loading} variant="contained" type="submit">
              Request
            </LoadingButton>
          </Stack>
        }
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Grid item md={4} xs={6} className={classes.modal}>
          <ReactAsyncSelect
            name="documents"
            label="Required Information"
            isMulti
            defaultOptions={documentOptions}
            fetchHandler={getKycPendingDocs}
          />
        </Grid>
        <Box sx={{ marginTop: 3 }}>
          <TextField
            {...register('remarks')}
            name="remarks"
            rows={3}
            multiline
            fullWidth
            label="Enter your remarks here"
            error={!!remarksError}
            helperText={remarksError}
          />
        </Box>
      </CustomModal>
    </FormProvider>
  );
};

const useStyles = makeStyles(() => ({
  customerText: {
    color: '#808080',
    fontSize: '15px',
    borderBottom:'none',
    padding:'8px'
  },
  documentContainer: {
    backgroundColor: '#D9D9D9',
    height: 200,
  },
  sectionContainer: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    height: 350,
    borderRadius: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: 2,
  },
  gridItem: {
    textAlign: 'center',
    marginTop: 1,
  },
  customerDetails: {
    color: 'black',
    fontSize: '13px',
    borderBottom:'none',
     padding:'8px',
     width:'250px'
  },
  modal: {
    marginTop: '10px',
    paddingTop: '10px',
  },
  title: {
    flexGrow: 1,
  },
  icon: {
    marginRight: 4,
    fontSize: 5,
    color: '#002EA7',
    width: 15,
    fontWeight: 700,
    marginTop: 2,
  },
  container: {
    maxWidth: '100%',
    backgroundColor: '#FFFFFF',
    height: '100%',
    padding: '16px',
    borderRadius: '8px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    marginTop: '16px',
  },
  customerGrid: {
    marginTop: '13px',
    display: 'grid',
    width: 'auto',
    gridTemplateColumns: 'max-content 1fr',
    gap: '8px',
    flex: 1,
  },
}));
