import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';

import { DeleteBidModalProps } from 'src/interfaces';

import CustomModal from 'src/components/modal';

interface DeleteBidForm {
  confirmation: string;
}

const DeleteBidModal: React.FC<DeleteBidModalProps> = ({ bidDetails, open, onClose, onDelete }) => {
  // React Hook Form setup
  const methods = useForm<DeleteBidForm>({
    defaultValues: {
      confirmation: '',
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<DeleteBidForm> = () => {
    onDelete();
    reset();
    onClose();
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  if (!bidDetails) {
    return null;
  }

  return (
    <CustomModal
      title="Delete Bid Confirmation"
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      buttons={
        <Stack direction="row" spacing={2}>
          <LoadingButton
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            type="submit"
            color="error"
            variant="contained"
            loading={isSubmitting}
          >
            Delete
          </LoadingButton>
        </Stack>
      }
    >
      <FormProvider {...methods}>
        <Typography pt={2}>
          Are you sure you want to delete <strong>{bidDetails.bidder_id || 'Unknown'}</strong>&#39;s bid to
          purchase <strong>{bidDetails.weight || 'N/A'}g</strong> of gold at{' '}
          <strong>₹{bidDetails.market_rate || '0'}</strong> per gram?
        </Typography>
      </FormProvider>
    </CustomModal>
  );
};

export default DeleteBidModal;
