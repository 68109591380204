import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { transformNotifications } from 'src/utils/notifications-utils';

// interfaces
import {
  readNotification,
  fetchAllNotifications,
  fetchNotificationsCount,
  fetchUnreadNotifications,
} from 'src/apis/notifications';

interface Notification {
  title: string;
  content: string;
  web_url: string;
  read: boolean;
  allNotificationsCount: number;
  unreadNotifcationsCount: number;
  allNotificationsRecords: number;
  unreadNotificationsRecords: number;
  allNotifications: any;
}

interface State {
  loading: boolean;
  allNotifications: string[];
  unreadNotifications: string[];
  allNotificationsCount: number;
  unreadNotifcationsCount: number;
  allNotificationsRecords: number;
  unreadNotificationsRecords: number;
  notificationsObject: any;
}

const initialState: State = {
  allNotifications: [],
  unreadNotifications: [],
  loading: false,
  allNotificationsCount: 0,
  unreadNotifcationsCount: 0,
  allNotificationsRecords: 0,
  unreadNotificationsRecords: 0,
  notificationsObject: {},
};

export const getAllNotifications = createAsyncThunk(
  'notifications/allNotifications',
  async ({ page }: { page: number }) => {
    const resp = await fetchAllNotifications(page);
    return resp;
  }
);

export const getUnreadNotifications = createAsyncThunk(
  'notifications/unreadNotifications',
  async ({ page }: { page: number }) => {
    const resp = await fetchUnreadNotifications(page);
    return resp;
  }
);

export const loadMoreNotifications = createAsyncThunk(
  'notifications/loadMoreNotifications',
  async ({ page }: { page: number }) => {
    const resp = await fetchUnreadNotifications(page);
    return resp;
  }
);

export const getNotificationsCount = createAsyncThunk('notifications/getCount', async () => {
  const resp = await fetchNotificationsCount();
  return resp;
});

export const markNotificationsAsRead = createAsyncThunk(
  'notifications/read',
  async ({ id }: { id: string }) => {
    await readNotification(id);
    return id;
  }
);

const notificationSlice = createSlice({
  name: 'Notifications',
  initialState,
  reducers: {
    clearNotifications(state) {
      state.allNotifications = [];
      state.unreadNotifications = [];
      state.allNotificationsCount = 0;
      state.unreadNotifcationsCount = 0;
      state.allNotificationsRecords = 0;
      state.unreadNotificationsRecords = 0;
    },
    incrementNotificationCount(state) {
      state.unreadNotifcationsCount += 1;
    },
    appendNotifications(state) {},
  },
  extraReducers: (builder) => {
    builder.addCase(getNotificationsCount.fulfilled, (state, action) => {
      state.allNotificationsCount = action.payload.total;
      state.unreadNotifcationsCount = action.payload.unread;
    });

    builder.addCase(getAllNotifications.pending, (state, action) => {
      state.loading = true;
      state.allNotificationsRecords = 0;
    });

    builder.addCase(getAllNotifications.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getAllNotifications.fulfilled, (state, action) => {
      state.loading = false;
      const { recordIds, recordObj } = transformNotifications(action.payload.records);
      state.notificationsObject = { ...state.notificationsObject, ...recordObj };

      if (action.payload.current_page > 1) {
        state.allNotifications = [...state.allNotifications, ...recordIds];
      } else {
        state.allNotifications = recordIds;
      }
      state.allNotificationsCount = action.payload.total_records;
      state.allNotificationsRecords = action.payload.total_pages * 1;
    });

    builder.addCase(getUnreadNotifications.pending, (state, action) => {
      state.loading = true;
      state.unreadNotificationsRecords = 0;
    });

    builder.addCase(getUnreadNotifications.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getUnreadNotifications.fulfilled, (state, action) => {
      state.loading = false;
      const { recordIds, recordObj } = transformNotifications(action.payload.records);

      state.notificationsObject = { ...state.notificationsObject, ...recordObj };

      if (action.payload.current_page > 1) {
        state.unreadNotifications = [...state.unreadNotifications, ...recordIds];
      } else {
        state.unreadNotifications = recordIds;
      }
      state.unreadNotifcationsCount = action.payload.total_records;
      state.unreadNotificationsRecords = action.payload.total_pages * 1;
    });

    builder.addCase(markNotificationsAsRead.fulfilled, (state, action) => {
      const notificationId: any = action.payload;
      if( state.notificationsObject[notificationId].read === false) {
        state.notificationsObject[notificationId].read = true;
        state.unreadNotifcationsCount -= 1;
        }
    });
  },
});

export const { clearNotifications, incrementNotificationCount } = notificationSlice.actions;
export default notificationSlice.reducer;
