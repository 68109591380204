import { getRegions, createRegion, updateRegion, RegionsParams } from 'src/apis/regions'; // Assuming RegionData is the type of data needed to create a region
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

interface RegionState {
  loading: boolean;
  regions: RegionsParams[]; // Assuming RegionData is the type of the region object
  current_page: number;
  page_size: number;
  totalRecords: number;
}

const initialState: RegionState = {
  loading: false,
  regions: [],
  current_page: 1,
  page_size: 10,
  totalRecords: 0,
};

/* eslint-disable no-useless-catch */
export const createARegion = createAsyncThunk(
  'regions/region_create',
  async (data: RegionsParams) => {
    try {
      const region = await createRegion(data);
      return region;
    } catch (error) {
      throw error;
    }
  }
);
/* eslint-disable no-useless-catch */
export const updateARegion = createAsyncThunk(
  'regions/region_update',
  async (data: RegionsParams) => {
    try {
      const region = await updateRegion(data);
      return region;
    } catch (error) {
      throw error;
    }
  }
);

/* eslint-disable no-useless-catch */
export const getAllRegions = createAsyncThunk(
  'regions/get_all',
  async ({
    page_size = 1,
    page_number = 10,
  }: {
    page_size?: number | null;
    page_number?: number | null;
  }) => {
    try {
      const regions = await getRegions(page_size, page_number);
      return regions;
    } catch (error) {
      throw error;
    }
  }
);
/* eslint-disable no-useless-catch */

const regionSlice = createSlice({
  name: 'Regions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get all regions
    builder
      .addCase(getAllRegions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllRegions.fulfilled, (state, action) => {
        state.loading = false;
        state.regions = action.payload.records;
        state.totalRecords = action.payload.total_records;
      })
      .addCase(getAllRegions.rejected, (state, action) => {
        state.loading = false;
      })

      // Create Region
      .addCase(createARegion.pending, (state) => {
        state.loading = true;
      })
      .addCase(createARegion.fulfilled, (state, action) => {
        state.loading = false;
        state.regions = [...state.regions, action.payload];
      })
      .addCase(createARegion.rejected, (state, action) => {
        state.loading = false;
      })

      // Update Region
      .addCase(updateARegion.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateARegion.fulfilled, (state, action) => {
        state.loading = false;
        state.regions = state.regions.map((region) => {
          if (region.id === action.payload.id) {
            return action.payload;
          }
          return region;
        });
      })
      .addCase(updateARegion.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default regionSlice.reducer;
