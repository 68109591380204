import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  BidderSaleRecord,
  AfterMeltingRecord,
  BeforeMeltingRecord,
} from 'src/interfaces';
import {
  deleteBatch,
  getAllBatches,
  getBatchesCount,
  updateMarketBatch,
  createMarketBatch,
  updateBidderSalesBatch,
  createBidderSalesBatch,
  updateAfterMeltingBatch,
  createAfterMeltingBatch,
  createBeforeMeltingBatch,
  updateBeforeMeltingBatch,
} from 'src/apis/market';

interface MarketState {
  loading: boolean;
  records: any[];
  currentPage: number;
  pageSize: number;
  totalRecords: number;
  currentTransaction: any;
  error: string | undefined;
  transactionCount: {
    BEFORE_MELTING: number;
    AFTER_MELTING: number;
    MARKET: number;
    BIDDER_SALE: number;
    ALL: number;
  };
}

// TODO : Define the initial state of the slice
const initialState: MarketState = {
  loading: false,
  records: [],
  currentPage: 1,
  pageSize: 10,
  totalRecords: 0,
  error: undefined,
  currentTransaction: null,
  transactionCount: {
    BEFORE_MELTING: 0,
    AFTER_MELTING: 0,
    MARKET: 0,
    BIDDER_SALE: 0,
    ALL: 0,
  },
};

export const fetchAllBatches = createAsyncThunk(
  'markets/fetchAllBatches',
  async ({
    state,
    pageSize,
    page,
    startDate,
    endDate,
    searchText,
  }: {
    state: string;
    pageSize: number;
    page: number;
    startDate: string;
    endDate: string;
    searchText: string;
  }) => {
    const response = await getAllBatches(state, pageSize, page, startDate, endDate, searchText);
    return response;
  }
);

export const createABeforeMeltingBatch = createAsyncThunk(
  'markets/createABeforeMeltingBatch',
  async ({ data }: { data: BeforeMeltingRecord }) => {
    const response = await createBeforeMeltingBatch(data);
    return response;
  }
);

export const updateABeforeMeltingBatch = createAsyncThunk(
  'markets/updateABeforeMeltingBatch',
  async ({ data }: { data: BeforeMeltingRecord }) => {
    const response = await updateBeforeMeltingBatch(data);
    return response;
  }
);

// ------------------ After Melting ------------------

export const createAAfterMeltingBatch = createAsyncThunk(
  'markets/createAAfterMeltingBatch',
  async ({ data }: { data: AfterMeltingRecord }) => {
    const response = await createAfterMeltingBatch(data);
    return { ...response, editing: data.editing };
  }
);

export const updateAAfterMeltingBatch = createAsyncThunk(
  'markets/updateAAfterMeltingBatch',
  async ({ data }: { data: AfterMeltingRecord }) => {
    const response = await updateAfterMeltingBatch(data);
    return { ...response, editing: data.editing };
  }
);

// ------------------ Market ------------------
export const createAMarketBatch = createAsyncThunk(
  'markets/createAMarketBatch',
  async ({ data }: { data: AfterMeltingRecord }) => {
    const response = await createMarketBatch(data);
    return { ...response, editing: data.editing };
  }
);

export const updateAMarketBatch = createAsyncThunk(
  'markets/updateAMarketBatch',
  async ({ data }: { data: AfterMeltingRecord }) => {
    const response = await updateMarketBatch(data);
    return { ...response, editing: data.editing };
  }
);

// ------------------ Bidder Sale ------------------

export const createABidderSalesBatch = createAsyncThunk(
  'bids/createABidderSalesBatch',
  async ({ data }: { data: BidderSaleRecord }) => {
    const response = await createBidderSalesBatch(data);
    return { ...response, editing: data.editing };
  }
);

export const updateABidderSalesBatch = createAsyncThunk(
  'bids/updateABidderSalesBatch',
  async ({ data }: { data: BidderSaleRecord }) => {
    const newBid = await updateBidderSalesBatch(data);
    return newBid;
  }
);

// ------------------ Delete ------------------

export const deleteABatch = createAsyncThunk(
  'markets/deleteABatch',
  async ({ id }: { id: string }) => {
    const response = await deleteBatch(id);
    return response;
  }
);

export const getCount = createAsyncThunk('markets/getCount', async () => {
  const response = await getBatchesCount();
  return response;
});

const marketSlice = createSlice({
  initialState,
  name: 'markets',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllBatches.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllBatches.fulfilled, (state, action) => {
      state.records = action.payload.records;
      state.totalRecords = action.payload.total_records;
      state.currentPage = action.payload.current_page;
      state.loading = false;
    });

    builder.addCase(fetchAllBatches.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getCount.fulfilled, (state, action) => {
      state.transactionCount = action.payload;
    });

    builder.addCase(createABeforeMeltingBatch.fulfilled, (state, action) => {
      state.loading = false;
      state.records.unshift(action.payload);
      state.totalRecords += 1;
      state.transactionCount.BEFORE_MELTING += 1;
    });

    builder.addCase(updateABeforeMeltingBatch.fulfilled, (state, action) => {
      const index = state.records.findIndex((record) => record.id === action.payload.id);
      state.records[index] = action.payload;
    });

    builder.addCase(createAAfterMeltingBatch.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.editing) {
        state.records.unshift(action.payload);
        state.totalRecords += 1;
        state.transactionCount.AFTER_MELTING += 1;
      }
    });

    builder.addCase(updateAAfterMeltingBatch.fulfilled, (state, action) => {
      const index = state.records.findIndex((record) => record.id === action.payload.id);
      state.records[index] = action.payload;
    });

    builder.addCase(createAMarketBatch.fulfilled, (state, action) => {
      if (action.payload.editing) {
        state.records.unshift(action.payload);
        state.totalRecords += 1;
        state.transactionCount.MARKET += 1;
      }
    });

    builder.addCase(updateAMarketBatch.fulfilled, (state, action) => {
      const index = state.records.findIndex((record) => record.id === action.payload.id);
      state.records[index] = action.payload;
    });

    builder.addCase(deleteABatch.fulfilled, (state, action) => {
      state.records = state.records.filter((record) => record.id !== action.payload.id);
      state.totalRecords -= 1;
      state.transactionCount.MARKET -= 1;
    });

    builder.addCase(createABidderSalesBatch.fulfilled, (state, action) => {
      state.records.unshift(action.payload);
      state.totalRecords += 1;
      state.transactionCount.BIDDER_SALE += 1;
    });

    builder.addCase(updateABidderSalesBatch.fulfilled, (state, action) => {
      const index = state.records.findIndex((record) => record.id === action.payload.id);
      state.records[index] = action.payload;
    });
  },
});

export default marketSlice.reducer;
