// @ts-nocheck
/* eslint-disable */

import { numberBetween0And3 } from 'src/utils/mobile-number';
import * as Yup from 'yup';

export const ServiceChargeSchema = Yup.object().shape({
    service_charge: numberBetween0And3,
    rate_14k_17k: Yup.number()
      .required('14k-17k Gold rate is required')
      .moreThan(0, '14k-17k Gold rate must be greater than 0')
      .typeError('14k-17k Gold rate is requiredr')
      .test(
        'is-not-negative-zero',
        'Gold rate should be a positive number',
        (value) => !Object.is(value, -0)
      )
      .test(
        'max-decimals',
        'Gold rate should be a number with up to 5 digits before the decimal and exactly 2 decimal places',
        (value) => /^\d{1,5}(\.\d{1,2})?$/.test(value?.toString() || '')
      ),
    rate_17k_21k: Yup.number()
      .required('17k-21k Gold rate is required')
      .moreThan(0, '17k-21k Gold rate must be greater than 0')
      .typeError('17k-21k Gold rate is required')
      .test(
        'is-not-negative-zero',
        'Gold rate should be a positive number',
        (value) => !Object.is(value, -0)
      )
      .test(
        'max-decimals',
        'Gold rate should be a number with up to 5 digits before the decimal and exactly 2 decimal places',
        (value) => /^\d{1,5}(\.\d{1,2})?$/.test(value?.toString() || '')
      ),
    margin_24k: Yup.number()
      .required('24k Gold rate is required')
      .moreThan(0, '24k Gold rate must be greater than 0')
      .typeError('24k Gold rate is requiredr')
      .test(
        'is-not-negative-zero',
        'Gold rate should be a positive number',
        (value) => !Object.is(value, -0)
      )
      .test(
        'max-decimals',
        'Gold rate should be a number with up to 5 digits before the decimal and exactly 2 decimal places',
        (value) => /^\d{1,5}(\.\d{1,2})?$/.test(value?.toString() || '')
      ),
    margin_22k: Yup.number()
      .required('22k Gold rate is required')
      .moreThan(0, '22k Gold rate must be greater than 0')
      .typeError('22k Gold rate is required')
      .test(
        'is-not-negative-zero',
        'Gold rate should be a positive number',
        (value) => !Object.is(value, -0)
      )
      .test(
        'max-decimals',
        'Gold rate should be a number with up to 5 digits before the decimal and exactly 2 decimal places',
        (value) => /^\d{1,5}(\.\d{1,2})?$/.test(value?.toString() || '')
      ),
    ornaments: Yup.array(
      Yup.object({
        wastage: Yup.number()
          .typeError('Wastage is required and must be a valid number')
          .moreThan(-1, 'Wastage should be a positive number')
          .max(Yup.ref('gross_weight'), 'Wastage cannot exceed the Gross weight')
          .test(
            'is-not-negative-zero',
            'Wastage should be a positive number',
            (value) => !Object.is(value, -0)
          ),
        net_weight: Yup.number()
          .typeError('Net Weight is required and must be a valid number')
          .moreThan(-1, 'Net Weight should be a positive number')
          .test(
            'is-not-negative-zero',
            'Net Weight should be a positive number',
            (value) => !Object.is(value, -0)
          ),
      })
    ),
  });
