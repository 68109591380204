import { AppStatus } from 'src/interfaces';

export const SalesStatus: AppStatus = {
  PENDING: {
    label: 'Pending',
    style: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'rgba(183, 110, 0, 1)',
      fontWeight: 700,
      maxWidth: 190,
      backgroundColor: 'rgba(255, 171, 0, 0.16)',
      fontSize: '0.9em',
      borderRadius: 1.2,
      padding: 0.9,
    },
  },

  REQUESTED: {
    label: 'Requested',
    style: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'rgba(183, 110, 0, 1)',
      fontWeight: 700,
      maxWidth: 190,
      backgroundColor: 'rgba(255, 171, 0, 0.16)',
      fontSize: '0.9em',
      borderRadius: 1.2,
      padding: 0.9,
    },
  },

  APPROVED: {
    label: 'Approved',
    style: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: 150,
      color: '#118D57',
      fontWeight: 700,
      backgroundColor: 'rgba(34, 197, 94, 0.16)',
      fontSize: '0.9em',
      padding: 0.9,
      borderRadius: 1.2,
    },
  },

  REJECTED: {
    label: 'Rejected',

    style: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: 150,
      color: '#7A0916',
      fontWeight: 700,
      backgroundColor: 'rgba(255, 86, 48, 0.16)',
      fontSize: '0.9em',
      padding: 0.9,
      borderRadius: 1.2,
    },
  },
};
