// React
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// MUI components
import { Box, Card, Button } from '@mui/material';

import { RootState } from 'src/store/store';

// Components
import CustomBreadcrumbs from 'src/components/custom-breadcrumb/custom-breadcrumb';

import AllBids from 'src/sections/components/tables/operations/bids';
import BidModal from 'src/sections/components/modals/operations/bidder-modal';

const RenderHeader = ({ onAdd }: { onAdd: () => void }) => {
  const { user } = useSelector((store: RootState) => store.user);
  const userRole = user?.role ?? '';

  return (
    <CustomBreadcrumbs
      heading="Bids"
      links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'Bids' }]}
      sx={{ mb: { xs: 3 } }}
      action={
        <Box sx={{ display: 'flex', gap: 1, mr: 2 }}>
          <Button variant="contained" onClick={onAdd} disabled={userRole === 'MELTING_TEAM'}>
            Add New Bid
          </Button>
        </Box>
      }
    />
  );
};

const Bid: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContext, setModalContext] = useState<'add' | 'edit'>('add');
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const handleAdd = () => {
    setModalContext('add');
    setSelectedRow(null);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedRow(null);
  };

  return (
    <div>
      <RenderHeader onAdd={handleAdd} />
      <Card sx={{ p: 1, pt: 5 }}>
        <AllBids />
      </Card>
      {isModalOpen && (
        <BidModal
          open={isModalOpen}
          onClose={handleCloseModal}
          rowDetails={selectedRow}
          context={modalContext}
          onSave={setSelectedRow}
        />
      )}
    </div>
  );
};

export default Bid;
