const formatINR = (value?: number | string): string => {
  // Return an empty string if value is empty, undefined, or null
  if (value === '' || value === undefined || value === null) return '';

  // Convert the value to a number if it's a string
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;

  // Check if the value is NaN (Not a Number) or null, but allow 0
  if (Number.isNaN(numericValue) || numericValue === null) return '';

  // Format the value according to the INR format
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    maximumFractionDigits: 2,
  })
    .format(numericValue)
    .replace('₹', '') // Removing the rupee symbol to avoid duplication
    .trim();
};

export default formatINR;
