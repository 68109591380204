import { useMemo } from 'react';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  useMaterialReactTable,
} from 'material-react-table';

import { TablePagination } from '@mui/material';

import { ColumnDefinition } from 'src/interfaces';

interface ReactTableProps {
  headers: MRT_ColumnDef<ColumnDefinition>[];
  rows: ColumnDefinition[];
  totalRecords: number;
  page: number;
  pageSize: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  enablePagination?: boolean;
  enableGlobalFilter?: boolean;
  enableColumnActions?: boolean;
  enableColumnOrdering?: boolean;
  enableStickyHeader?: boolean;
  enableSorting?: boolean;
  enableFilters?: boolean;
  enableColumnFilters?: boolean;
  enableTopToolbar?: boolean;
  enableBottomToolbar?: boolean;
  loading?: boolean;
  enableColumnPinning?: boolean;
  initialState?: any;
  leftColumnPinning?: string[];
  rightColumnPinning?: string[];
  enableEditing?: boolean;
  detailPanel?: any;
  hideCustomPagination?: boolean;
  hideColumns?: string[];
}
const ReactTable: React.FC<ReactTableProps> = ({
  headers,
  rows,
  totalRecords,
  page,
  pageSize,
  onPageChange,
  onRowsPerPageChange,
  enablePagination,
  enableGlobalFilter,
  enableColumnActions,
  enableColumnOrdering,
  enableStickyHeader,
  enableSorting,
  enableFilters,
  enableColumnFilters,
  enableTopToolbar,
  enableBottomToolbar,
  loading,
  rightColumnPinning,
  leftColumnPinning,
  enableEditing,
  detailPanel,
  hideCustomPagination,
  hideColumns,
}) => {
  const columns = useMemo<MRT_ColumnDef<ColumnDefinition>[]>(() => headers, [headers]);
  const data = useMemo(() => rows, [rows, loading]) || [];

  const columnVisibility = useMemo(() => {
    const visibility: { [key: string]: boolean } = {
      'mrt-row-expand': false,
      'mrt-row-actions': false,
    };
    hideColumns?.forEach((column) => {
      visibility[column] = false;
    });
    return visibility;
  }, [hideColumns]);

  const table = useMaterialReactTable({
    columns,
    data,
    enablePagination,
    enableGlobalFilter,
    enableColumnActions,
    enableColumnOrdering,
    enableStickyHeader,
    enableSorting,
    enableFilters,
    enableColumnFilters,
    enableTopToolbar,

    enableBottomToolbar,
    enableStickyFooter: true,
    initialState: {
      columnVisibility,
      columnPinning: {
        left: leftColumnPinning,
        right: rightColumnPinning,
      },
    },
    muiSkeletonProps: {
      animation: 'wave',
    },
    state: {
      showSkeletons: loading,
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
    muiTableHeadCellProps: {
      align: 'center',
      sx: {
        backgroundColor: '#F4F6F8',
      },
    }, // ('modal', and 'custom' are also available)
    editDisplayMode: 'row',
    enableEditing,
    enableExpandAll: false,
    renderDetailPanel: detailPanel,
  });

  return (
    <>
      <MaterialReactTable table={table} />
      {!hideCustomPagination ? (
        <TablePagination
          component="div"
          count={totalRecords}
          page={page}
          rowsPerPage={pageSize}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      ) : null}
    </>
  );
};

ReactTable.defaultProps = {
  enablePagination: false,
  enableGlobalFilter: false,
  enableColumnActions: false,
  enableColumnOrdering: false,
  enableStickyHeader: false,
  enableSorting: false,
  enableFilters: false,
  enableColumnFilters: false,
  enableTopToolbar: false,
  enableBottomToolbar: false,
  enableColumnPinning: false,
  initialState: {},
  leftColumnPinning: [],
  rightColumnPinning: [],
  enableEditing: false,
  detailPanel: null,
  hideCustomPagination: false,
  hideColumns: [],
};

export default ReactTable;
