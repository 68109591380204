import { forwardRef } from 'react';
import { useNavigate } from 'react-router';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

import { fToNow } from 'src/utils/format-time';


// ----------------------------------------------------------------------

// Define the type for notification
interface Notification {
  id: string;
  title: string;
  content: string;
  created_at: Date;
  read: boolean;
  avatarUrl?: string | null;
  web_url: string | null;
}

// Define the props for NotificationItem component
interface NotificationItemProps {
  notification: Notification;
  onClick: (val: string) => void;
}

const NotificationItem = forwardRef<HTMLDivElement, NotificationItemProps>(
  ({ notification, onClick }, ref) => {
    const navigate = useNavigate();

    const renderText = (
      <ListItemText
        primary={
          <Typography fontWeight="600" variant="body2">
            {notification.title}
          </Typography>
        }
        secondary={
          <Stack
          
            sx={{ typography: 'body2', color: 'text.disabled' }}
          >
            <Box sx={{mb:2}}>{`${notification.content}.`}</Box>
            <Typography variant="caption"> {fToNow(notification.created_at)}</Typography>
          </Stack>
        }
      />
    );

    const renderUnReadBadge = !notification.read && (
      <Box
        sx={{
          top: 26,
          width: 8,
          height: 8,
          right: 20,
          borderRadius: '50%',
          bgcolor: 'info.main',
          position: 'absolute',
        }}
      />
    );

    // Define the click handler
    const handleClick = () => {
      if (notification.web_url) {
        const url = notification.web_url;
        if (navigate) {
          if (url.startsWith('/')) {
            navigate(url, { replace: true });
          } else {
            window.location.href = url;
          }
          onClick(notification.id);
        }
      }
    };

    return (
      <div ref={ref}>
        <ListItemButton
          onClick={handleClick}
          disableRipple
          sx={{
            p: 2.5,
            alignItems: 'flex-start',
            backgroundColor: (theme) =>
              !notification.read ? `${theme.palette.info.lighter}` : null,
            borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderUnReadBadge}

          <Stack sx={{ flexGrow: 1 }}>{renderText}</Stack>
        </ListItemButton>
      </div>
    );
  }
);

export default NotificationItem;

// ----------------------------------------------------------------------
