import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getQuotations } from 'src/apis/quotations';
import { createWalkin, updateCustomerDetails } from 'src/apis/customers';
import { ReleaseFormValues, CreateTransactionParam } from 'src/interfaces';
import { createPledgeEstimation, updatePledgeEstimation } from 'src/apis/release';
import { getTransactions, updateTransactionCustomerDetails } from 'src/apis/transactions';
import { assayerApproval, createEstimation, requestSalesApproval } from 'src/apis/estimations';

interface TransactionState {
  loading: boolean;
  currentPage: number;
  currentPageSize: number;
  totalRecords: number;
  records: any[];
  currentTransaction: any;
  error: string | undefined;
}

const initialState: TransactionState = {
  loading: false,
  error: undefined,
  currentPage: 1,
  currentPageSize: 10,
  totalRecords: 0,
  records: [],
  currentTransaction: null,
};

// create a branch walkin
export const createAWalkin = createAsyncThunk(
  'transactions/walkin_create',
  async (data: CreateTransactionParam) => {
    const walkin = await createWalkin(data);
    return walkin;
  }
);

// fetch transactions by status
export const getAllTransactions = createAsyncThunk(
  'transactions/get_alltransactions',
  async ({
    status,
    pageSize,
    page,
    startDate,
    endDate,
    searchText,
    transactionType,
  }: {
    status?: string;
    pageSize?: number;
    page?: number;
    startDate?: string;
    endDate?: string;
    searchText?: string;
    transactionType?: string;
  }) => {
    const transactions = await getTransactions(
      status || '',
      pageSize,
      page,
      startDate,
      endDate,
      searchText,
      transactionType
    );
    return transactions;
  }
);

// get quotations
export const getAQuotations = createAsyncThunk(
  'transactions/quotations',
  async ({
    status,
    pageSize,
    page,
    startDate,
    endDate,
    searchText,
    transactionType,
  }: {
    status?: string;
    pageSize?: number;
    page?: number;
    startDate?: string;
    endDate?: string;
    searchText?: string;
    transactionType?: string;
  }) => {
    const transactions = await getQuotations(
      status || '',
      pageSize,
      page,
      startDate,
      endDate,
      searchText,
      transactionType
    );
    return transactions;
  }
);

export const updateCustomer = createAsyncThunk(
  'transactions/customer_details',
  async ({ id, body }: { id: string; body: any }) => {
    const walkin = await updateCustomerDetails(id, body);
    return walkin;
  }
);

export const submitCustomerBasicDetails = createAsyncThunk(
  'customer/update',
  async ({ id, body }: { id: string; body: any }) => {
    const walkin = await updateTransactionCustomerDetails(id, body);
    return walkin;
  }
);

export const createAEstimation = createAsyncThunk(
  'transaction/create_estimation',
  async ({ data, transactionId }: any) => {
    const estimation = await createEstimation(data, transactionId);
    return estimation;
  }
);

export const submitAssayerApproval = createAsyncThunk(
  'transaction/assayer_approval',
  async ({ data, transactionId, estimationId }: any) => {
    const estimation = await assayerApproval(data, transactionId, estimationId);
    return estimation;
  }
);

export const requestSales = createAsyncThunk(
  'transaction/request_sales',
  async ({ data, transactionId, estimationId }: any) => {
    const estimation = await requestSalesApproval(data, transactionId, estimationId);
    return estimation;
  }
);

// ------------- Pledge Estimation -----------------------------------
export const createAPledgeEstimation = createAsyncThunk(
  'transactions/create_pledge_estimation',
  async ({
    estimation,
    transaction_id,
  }: {
    estimation: ReleaseFormValues;
    transaction_id: string;
  }) => {
    const resp = await createPledgeEstimation(estimation, transaction_id);
    return resp;
  }
);

export const updateAPledgeEstimation = createAsyncThunk(
  'transactions/update_pledge_estimation',
  async ({
    estimation,
    transaction_id,
  }: {
    estimation: ReleaseFormValues;
    transaction_id: string;
  }) => {
    const resp = await updatePledgeEstimation(estimation, transaction_id);
    return resp;
  }
);

const transactionSlice = createSlice({
  name: 'Transactions',
  initialState,
  reducers: {
    setCurrentTransactions(state, action) {
      state.currentTransaction = action.payload;
    },
    updateCurrentTransactions(state, action) {
      state.currentTransaction = { ...state.currentTransaction, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    // create walkin events
    builder.addCase(createAWalkin.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(createAWalkin.rejected, (state, action) => {
      state.error = action.error?.message;
      state.loading = false;
    });

    builder.addCase(createAWalkin.fulfilled, (state, action) => {
      state.records.unshift(action.payload);
      state.totalRecords += state.totalRecords;
      state.loading = false;
    });

    // submit customer basic details
    builder.addCase(submitCustomerBasicDetails.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(submitCustomerBasicDetails.rejected, (state, action) => {
      state.error = action.error?.message;
      state.loading = false;
    });

    builder.addCase(submitCustomerBasicDetails.fulfilled, (state, action) => {
      state.currentTransaction = {
        ...action.payload.transaction,
        customer: action.payload.customer,
      };
      state.loading = false;
    });

    // update customer basic details
    builder.addCase(updateCustomer.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateCustomer.rejected, (state, action) => {
      state.error = action.error?.message;
      state.loading = false;
    });

    builder.addCase(updateCustomer.fulfilled, (state, action) => {
      state.currentTransaction.customer = action.payload;
      state.loading = false;
    });

    // estimation
    builder.addCase(createAEstimation.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(createAEstimation.rejected, (state, action) => {
      state.error = action.error?.message;
      state.loading = false;
    });

    builder.addCase(createAEstimation.fulfilled, (state, action) => {
      state.loading = false;
    });

    // request sales
    builder.addCase(requestSales.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(requestSales.rejected, (state, action) => {
      state.error = action.error?.message;
      state.loading = false;
    });

    builder.addCase(requestSales.fulfilled, (state, action) => {
      state.loading = false;
    });

    // assayer approval
    builder.addCase(submitAssayerApproval.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(submitAssayerApproval.rejected, (state, action) => {
      state.error = action.error?.message;
      state.loading = false;
    });

    builder.addCase(submitAssayerApproval.fulfilled, (state, action) => {
      state.loading = false;
    });

    // ------------------- GET EVENTS -------------------------------------------
    // get all walkin events
    builder.addCase(getAllTransactions.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getAllTransactions.rejected, (state, action) => {
      state.error = action.error?.message;
      state.loading = false;
    });

    builder.addCase(getAllTransactions.fulfilled, (state, action) => {
      state.records = action.payload.records || [];
      state.currentPageSize = action.payload.total_pages;
      state.totalRecords = action.payload.total_records;
      state.currentPage = action.payload.current_page;
      state.loading = false;
    });

    // get quotations
    builder.addCase(getAQuotations.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getAQuotations.rejected, (state, action) => {
      state.error = action.error?.message;
      state.loading = false;
    });

    builder.addCase(getAQuotations.fulfilled, (state, action) => {
      state.records = action.payload.records || [];
      state.currentPageSize = action.payload.total_pages;
      state.totalRecords = action.payload.total_records;
      state.currentPage = action.payload.current_page;
      state.loading = false;
    });

    builder.addCase(createAPledgeEstimation.fulfilled, (state, action) => {
      state.currentTransaction = action.payload.transaction;
      state.loading = false;
    });

    // create pledge
    builder.addCase(createAPledgeEstimation.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(createAPledgeEstimation.rejected, (state, action) => {
      state.error = action.error?.message;
      state.loading = false;
    });

    builder.addCase(updateAPledgeEstimation.fulfilled, (state, action) => {
      state.currentTransaction = action.payload.transaction;
      state.loading = false;
    });

    builder.addCase(updateAPledgeEstimation.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateAPledgeEstimation.rejected, (state, action) => {
      state.error = action.error?.message;
      state.loading = false;
    });
  },
});

export const { setCurrentTransactions, updateCurrentTransactions } = transactionSlice.actions;
export default transactionSlice.reducer;
