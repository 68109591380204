import { Theme } from '@mui/material/styles';
import { LoadingButtonProps, loadingButtonClasses } from '@mui/lab/LoadingButton';

// ----------------------------------------------------------------------

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    'primary-outline': true;
    ghost: true;
  }
}

export function loadingButton(theme: Theme) {
  return {
    MuiLoadingButton: {
      variants: [
        {
          props: { variant: 'primary-outline' },
          style: {
            textTransform: 'none',
            border: `1px solid ${theme.palette.primary.main}`,
            color: `${theme.palette.primary.main}`,
          },
        },
        {
          props: { variant: 'ghost' },
          style: {
            textTransform: 'none',

            color: `${theme.palette.primary.main}`,
            '&:disabled': {
              backgroundColor: 'transparent',
              color: `${theme.palette.primary.main}`,
              border: 0,
              opacity: 0.4,
            },
          },
        },
      ],

      styleOverrides: {
        root: ({ ownerState }: { ownerState: LoadingButtonProps }) => ({
          ...(ownerState.variant === 'soft' && {
            '&.Mui-disabled': {
              background: '#e9e9e9',
              color: '#c7c7c7',
              borderColor: '#e4e4e4',
            },

            [`& .${loadingButtonClasses.loadingIndicatorStart}`]: {
              left: 10,
            },
            [`& .${loadingButtonClasses.loadingIndicatorEnd}`]: {
              right: 14,
            },
            ...(ownerState.size === 'small' && {
              [`& .${loadingButtonClasses.loadingIndicatorStart}`]: {
                left: 10,
              },
              [`& .${loadingButtonClasses.loadingIndicatorEnd}`]: {
                right: 10,
              },
            }),
          }),
        }),
      },
    },
  };
}
