import * as Yup from 'yup';

const meltingSummarySchema = Yup.object().shape({
  crushed_stone: Yup.number()
    .typeError('Crushed Stone must be a number')
    .min(0, 'Crushed Stone must be at least 0')
    .max(99999, 'Crushed Stone must be less than or equal to 99999')
    .required('Crushed Stone is required'),
  whole_stone: Yup.number()
    .typeError('Whole Stone must be a number')
    .min(0, 'Whole Stone must be at least 0')
    .max(99999, 'Whole Stone must be less than or equal to 99999')
    .required('Whole Stone is required'),
  melting_waste: Yup.number()
    .typeError('Melting Waste must be a number')
    .min(0, 'Melting Waste must be at least 0')
    .max(99999, 'Melting Waste must be less than or equal to 99999')
    .required('Melting Waste is required'),
  phy_melting_waste: Yup.number()
    .typeError('Phy Melting Waste must be a number')
    .min(0, 'Phy Melting Waste must be at least 0')
    .max(99999, 'Phy Melting Waste must be less than or equal to 99999')
    .required('Phy Melting Waste is required'),
  am_weight: Yup.number()
    .typeError('AM Weight must be a number')
    .min(0, 'AM Weight must be at least 0')
    .max(99999, 'AM Weight must be less than or equal to 99999')
    .required('AM Weight is required'),
  net_gain: Yup.number()
    .typeError('Net Gain must be a number')
    .min(0, 'Net Gain must be at least 0')
    .max(99999, 'Net Gain must be less than or equal to 99999')
    .required('Net Gain is required'),
});

export default meltingSummarySchema;