export const API = 'v1';

export const auth = {
  me: `${API}/auth/me`,
  login: `${API}/auth/login`,
  userlookup: `${API}/auth/user_lookup`,
  set_password: `${API}/auth/set_password`,
  reset_password: '/v1/auth/reset_password',
  find_user: `/v1/auth/find_user`,
};

// should be regions
export const regions = {
  getEmployees: `${API}/employees/select`,
  getBranches: `${API}/branches/select`,
  createRegion: `${API}/region`,
  getRegionsById: `${API}/region`,
  getAllRegions: `${API}/region`,
  updateRegion: `${API}/region`,
};

export const transaction = {
  index: `${API}/transactions`,
  walkin: '/v1/transactions/walkin',
  basic_details: '/v1/customers/basic_details',
  transactionsCount: '/v1/transactions/count',
  getWalkins: '/v1/transactions?status=WALKIN',
  pendingEstimations: '/v1/transactions?status=ESTIMATION_PENDING',
  getTransactionById: `${API}/transactions`,
};

export const estimation = {
  createEstimation: `${API}/estimations/create`,
  updateEstimation: `${API}/estimations`,
  getEstimations: `${API}/estimations`,
  goldValuation: `${API}/estimations`,
  requestSales: `${API}/estimations`,
  branchKYC: `${API}/estimations`,
  postGoldRateApprovals: `${API}/estimations`,
};

export const customer = {
  getDetailsById: `${API}/customers`,
  customerUpdate: `${API}/customers/basic_details`,
};

// sales
export const sales = {
  createGoldRate: `${API}/sales/gold_rate`,
  getUpdateHistory: `${API}/sales/gold_rate/history`,
  getLiveGoldRate: `${API}/sales/gold_rate/live`,
  getPledgeEstimationCount: `${API}/release/pledge_estimation/sales/count`,
  getPledgeEstimations: `${API}/release/pledge_estimations`,
  salesApproval: `${API}/release/pledge_estimation/sales_approval`,
  paymentApprovals: `${API}/release/payments/sales_approvals`,
  getPaymentApprovalsCount: `${API}/release/payments/sales_approvals/count`,
  releasePaymentApproval: `${API}/release/payment/sales_approval`,
  getNegotiationApprovalsCount: `${API}/estimations/negotiation_approvals/count`,
  getNegotiationApprovals: `${API}/estimations/negotiation_approvals`,
  getServiceChargeApprovals: `${API}/estimations/service_charge_approvals`,
  getServiceChargeApprovalsCount: `${API}/estimations/service_charge_approvals/count`,
  getGoldRateApprovals: `${API}/estimations/gold_rate_approvals`,
  getGoldRateApprovalsCount: `${API}/estimations/gold_rate_approvals/count`,
};

// branch kyc
export const kyc = {
  validateIdProof: `${API}/kyc/verification`,
  updateCustomerKyc: `${API}/kyc`,
  getDocuments: `${API}/kyc`,
  postCustomerDetails: `${API}/kyc/branch_kyc`,
  getKycDetails: `${API}/kyc`,
  kycCount: `${API}/kyc/count`,
  callCustomer: `${API}/kyc/call_customer`,
};
export const googlePlaces = {
  placesSuggestions: `${API}/public/places`,
};

export const customLocations = {
  placesSuggestions: `${API}/public/custom_locations`,
};

// Payments
export const payments = {
  getPayments: `${API}/payments`,
  getCounts: `${API}/payments/count`,
  getPaymentsById: `${API}/transactions`,
  getReleasePayments: `${API}/payments/release`,
  getReleaseCounts: `${API}/payments/release_count`,
  uploadReleasePaymentScreenshot: `${API}/transactions`,
};

// Quotations
export const quotations = {
  createQuotation: `${API}/quotations`,
  index: `${API}/quotations`,
  updateQuotations: `${API}/quotations`,
};

// Pennydrop
export const pennyDrop = {
  getPayment: `${API}/payments`,
  getOtp: `${API}/payments/verify_otp`,
  resentOtp: `${API}/payments/generate_otp`,
  updateBank: `${API}/kyc`,
};

// Notifications
export const notifications = {
  subscribe: `${API}/notifications/subscribe`,
  unsubscribe: `${API}/notifications/unsubscribe`,
  getAllNotifications: `${API}/notifications`,
  getunReadNotifications: `${API}/notifications?read=false`,
  getnotificationsCount: `${API}/notifications/count`,
  markNotificationsAsRead: `${API}/notifications`,
};

// Telesales
export const telesales = {
  getCount: `${API}/telesales/calls/count`,
  updateCustomer: `${API}/telesales/leads`,
  createLead: `${API}/telesales/leads`,
  getTransaction: `${API}/telesales/calls`,
  mergeLead: `${API}/ telesales/calls`,
  callCustomer: `${API}/ telesales/outbound_call`,
  branchSelect: `${API}/branches/select`,
  getFollowups: `${API}/telesales/leads`,
  getFollowupCounts: `${API}/telesales/leads/count`,
  getCurrentLeads: `${API}/telesales/current_leads`,
  assignLeads: `${API}/employees/select`,
  assignNewLeads: `${API}/telesales/calls`,
};

// Takeover's release gold
export const release = {
  create: `${API}/release/pledge_estimation`,
  get: `${API}/release`,
  update: `${API}/release/pledge_estimations`,
  getTakeoverAgreement: `${API}/transactions`,
  submitAgreement: `${API}/release`,
  submitFinalAmount: `${API}/release`,
  makePayment: `${API}/transactions`,
  receivedPayment: `${API}/payments`,
  releasePickup: `${API}/release`,
};

export const orders = {
  getAllOrders: `${API}/orders`,
  getOrdersCount: `${API}/orders/count`,
  getOrderSum: `${API}/orders/sum`,
};

// Bids
export const bids = {
  fetchBids: `${API}/bids`,
  createBid: `${API}/bids`,
  updateBid: `${API}/bids`,
  deleteBid: `${API}/bids`,
  fetchBidsSum: `${API}/bids/sum`,
};

// Bidder
export const bidder = {
  fetchBidders: `${API}/bidders`,
  createBidder: `${API}/bidders`,
};

// Melting
export const melting = {
  getMeltingCount: `${API}/melting/count`,
  getMelting: `${API}/melting`,
  updateMelting: `${API}/melting`,
  getMeltingSum: `${API}/melting/sum`,
  getMeltingAudits: `${API}/melting/report`,
  getMeltingReports: `${API}/melting/sum`,
  getCollectionReports: `${API}/orders/report`,
  getMeltingSummary: `${API}/melting/summary`,
};

export const markets = {
  getLatestBatch: `${API}/batches/latest`,
  getAllBatches: `${API}/batches`,
  getBatchesCount: `${API}/batches/count`,
  getBatchesOptions: `${API}/batches/select`,
  createBeforeMeltingBatch: `${API}/batches/before_melting`,
  createMarketBatch: `${API}/batches/market`,
  updateBeforeMeltingBatch: `${API}/batches/before_melting`,
  deleteBeforeMeltingBatch: `${API}/batches/before_melting`,
  updateAfterMeltingBatch: `${API}/batches`,
  deleteAfterMeltingBatch: `${API}/batches`,
  updateMarketBatch: `${API}/batches`,
  deleteMarketBatch: `${API}/batches`,
  createAfterMeltingBatch: `${API}/batches/after_melting`,
  updateSoldBatch: `${API}/batches/`,
  getBids: `${API}/bids/select`,
  createBidderSales: `${API}/batches/bidder_sale`,
  updateBidderSales: `${API}/batches`,
};

export const bidderCalculation = {
  createBidderTable: `${API}/purchases`,
  getPurchaseDetails: `${API}/purchases`,
  getMarketRates: `${API}/bids/select`,
  createPurchaseOrder: `${API}/purchases/`,
};
