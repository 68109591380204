import { Estimation } from 'src/interfaces';

import API from '..';
import { estimation } from '../entpoints';

export const createEstimation = async (esimationData: Estimation, transactionId: string) => {
  try {
    const formdata = new FormData();

    formdata.append(`customer_expected_amount`, esimationData.customer_expected_amount?.toString());
    formdata.append(`transaction_id`, transactionId);
    formdata.append(`final_amount`, esimationData.final_amount?.toString());
    formdata.append(`service_charge_amount`, esimationData.service_charge_amount?.toString());
    formdata.append(`service_charge`, esimationData.service_charge?.toString());

    // send deleted ids
    if (esimationData.deleted_ids?.length) {
      esimationData.deleted_ids.forEach((item) => {
        formdata.append(`deleted_ids[]`, item.toString());
      });
    }

    // gold rates
    formdata.append(`margin_24k`, esimationData.margin_24k?.toString() || '');
    formdata.append(`margin_22k`, esimationData.margin_22k?.toString() || '');
    formdata.append(`rate_17k_21k`, esimationData.rate_17k_21k?.toString() || '');
    formdata.append(`rate_14k_17k`, esimationData.rate_14k_17k?.toString() || '');

    // ornaments
    esimationData.ornaments.forEach((ornament, index) => {
      formdata.append(`ornaments[${index}][id]`, ornament.id || '');
      formdata.append(`ornaments[${index}][name]`, ornament.ornament_name);
      formdata.append(`ornaments[${index}][ornament_image]`, ornament.ornament_image);
      formdata.append(`ornaments[${index}][weight_image]`, ornament.weight_image);
      formdata.append(`ornaments[${index}][purity_image]`, ornament.purity_image);

      formdata.append(`ornaments[${index}][gold_rate]`, ornament.gold_rate?.toString() || '');
      formdata.append(`ornaments[${index}][gross_weight]`, ornament.gross_weight?.toString() || '');
      formdata.append(`ornaments[${index}][net_weight]`, ornament.net_weight?.toString());
      formdata.append(`ornaments[${index}][purity]`, ornament.purity?.toString());
      formdata.append(`ornaments[${index}][stone_weight]`, ornament.stone_weight?.toString());
      formdata.append(`ornaments[${index}][wastage]`, ornament.wastage?.toString() || '');
      formdata.append(`ornaments[${index}][branch_purity]`, ornament?.branch_purity);
      formdata.append(`ornaments[${index}][purity_value]`, ornament.purity_value?.toString());
      formdata.append(`ornaments[${index}][amount]`, ornament.amount?.toString() || '');
    });

    const resp = await API.post(`${estimation.createEstimation}`, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

// Update estimation by id

export const updateEstimation = async (
  esimationData: Estimation,
  transactionId: string,
  estimationId: string
) => {
  try {
    const formdata = new FormData();
    formdata.append(`transaction_id`, transactionId);
    formdata.append(`customer_expected_amount`, esimationData.customer_expected_amount?.toString());
    formdata.append(`final_amount`, esimationData.final_amount?.toString());
    formdata.append(`service_charge_amount`, esimationData.service_charge_amount?.toString());
    formdata.append(`service_charge`, esimationData.service_charge?.toString());

    // send deleted ids
    if (esimationData.deleted_ids?.length) {
      esimationData.deleted_ids.forEach((item) => {
        formdata.append(`deleted_ids[]`, item.toString());
      });
    }
    // ornaments
    esimationData.ornaments.forEach((ornament, index) => {
      formdata.append(`ornaments[${index}][id]`, ornament.id || '');
      formdata.append(`ornaments[${index}][name]`, ornament.ornament_name);
      formdata.append(`ornaments[${index}][ornament_image]`, ornament.ornament_image);
      formdata.append(`ornaments[${index}][weight_image]`, ornament.weight_image);
      formdata.append(`ornaments[${index}][purity_image]`, ornament.purity_image);
      formdata.append(`ornaments[${index}][gold_rate]`, ornament.gold_rate?.toString() || '');
      formdata.append(`ornaments[${index}][gross_weight]`, ornament.gross_weight?.toString() || '');
      formdata.append(`ornaments[${index}][net_weight]`, ornament.net_weight?.toString());
      formdata.append(`ornaments[${index}][purity]`, ornament.purity?.toString());
      formdata.append(`ornaments[${index}][stone_weight]`, ornament.stone_weight?.toString());
      formdata.append(`ornaments[${index}][wastage]`, ornament.wastage?.toString() || '');
      formdata.append(`ornaments[${index}][branch_purity]`, ornament?.branch_purity);
      formdata.append(`ornaments[${index}][purity_value]`, ornament.purity_value?.toString());
      formdata.append(`ornaments[${index}][amount]`, ornament.amount?.toString() || '');
    });
    const resp = await API.patch(`${estimation.updateEstimation}/${estimationId}`, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

// Get estimation details by id
export const getEstimationById = async (estimationId: string) => {
  try {
    const resp = await API.get(`${estimation.getEstimations}/${estimationId}`);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

// TODO:
export const assayerApproval = async (
  estimationData: Estimation,
  transactionId: string,
  estimationId: string
) => {
  try {
    const formdata = new FormData();
    formdata.append(
      `customer_expected_amount`,
      estimationData.customer_expected_amount?.toString()
    );
    formdata.append(`transaction_id`, transactionId);
    formdata.append(`final_amount`, estimationData.final_amount?.toString());
    formdata.append(`service_charge_amount`, estimationData.service_charge_amount?.toString());
    // ornaments
    estimationData.ornaments.forEach((ornament, index) => {
      formdata.append(`ornaments[${index}][net_weight]`, ornament.net_weight?.toString());
      formdata.append(`ornaments[${index}][stone_weight]`, ornament.stone_weight?.toString());
      formdata.append(`ornaments[${index}][amount]`, ornament.amount?.toString() || '');
      formdata.append(`ornaments[${index}][id]`, ornament.id || '');
      formdata.append(`ornaments[${index}][purity_value]`, ornament.purity_value?.toString());
    });
    const resp = await API.patch(
      `${estimation.goldValuation}/${estimationId}/gold_valuation`,
      formdata,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

// request sales approval
export const requestSalesApproval = async (
  esimationData: Estimation,
  transactionId: string,
  estimationId: string
) => {
  try {
    const formdata = new FormData();
    formdata.append(`customer_expected_amount`, esimationData.customer_expected_amount?.toString());
    formdata.append(`transaction_id`, transactionId);
    formdata.append(`final_amount`, esimationData.final_amount?.toString());
    formdata.append(`service_charge_amount`, esimationData.service_charge_amount?.toString());
    formdata.append(`service_charge`, esimationData.service_charge?.toString());

    // ornaments
    esimationData.ornaments.forEach((ornament, index) => {
      formdata.append(`ornaments[${index}][id]`, ornament.id || '');
      formdata.append(`ornaments[${index}][name]`, ornament.ornament_name);
      formdata.append(`ornaments[${index}][ornament_image]`, ornament.ornament_image);
      formdata.append(`ornaments[${index}][weight_image]`, ornament.weight_image);
      formdata.append(`ornaments[${index}][purity_image]`, ornament.purity_image);
      formdata.append(`ornaments[${index}][gold_rate]`, ornament.gold_rate?.toString() || '');
      formdata.append(`ornaments[${index}][gross_weight]`, ornament.gross_weight?.toString() || '');
      formdata.append(`ornaments[${index}][net_weight]`, ornament.net_weight?.toString());
      formdata.append(`ornaments[${index}][purity]`, ornament.purity?.toString());
      formdata.append(`ornaments[${index}][stone_weight]`, ornament.stone_weight?.toString());
      formdata.append(`ornaments[${index}][wastage]`, ornament.wastage?.toString() || '');
      formdata.append(`ornaments[${index}][branch_purity]`, ornament?.branch_purity);
      formdata.append(`ornaments[${index}][purity_value]`, ornament.purity_value?.toString());
      formdata.append(`ornaments[${index}][amount]`, ornament.amount?.toString() || '');
    });
    const resp = await API.patch(
      `${estimation.requestSales}/${estimationId}/request_sales`,
      formdata,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const salesApproval = async (
  esimationData: Estimation,
  transactionId: string,
  rejected: boolean,
  estimationId: string
) => {
  try {
    const formdata = new FormData();
    formdata.append(`transaction_id`, transactionId);
    formdata.append(`final_amount`, esimationData.final_amount?.toString());
    formdata.append(`service_charge_amount`, esimationData.service_charge_amount?.toString());
    formdata.append(`service_charge`, esimationData.service_charge?.toString());
    formdata.append(`rejected`, rejected.toString());

    formdata.append(`remark`, esimationData.sales_remark?.toString() || '');
    formdata.append(`updated_margin_24k`, esimationData.updated_margin_24k?.toString() || '');
    formdata.append(`updated_margin_22k`, esimationData.updated_margin_22k?.toString() || '');
    formdata.append(`updated_rate_17k_21k`, esimationData.updated_rate_17k_21k?.toString() || '');
    formdata.append(`updated_rate_14k_17k`, esimationData.updated_rate_14k_17k?.toString() || '');
    formdata.append(`is_rates_updated`, esimationData.is_rates_updated?.toString() || 'false');

    // ornaments
    esimationData.ornaments.forEach((ornament, index) => {
      formdata.append(`ornaments[${index}][id]`, ornament.id || '');
      formdata.append(`ornaments[${index}][name]`, ornament.ornament_name);
      formdata.append(`ornaments[${index}][ornament_image]`, ornament.ornament_image);
      formdata.append(`ornaments[${index}][weight_image]`, ornament.weight_image);
      formdata.append(`ornaments[${index}][purity_image]`, ornament.purity_image);
      formdata.append(`ornaments[${index}][gold_rate]`, ornament.gold_rate?.toString() || '');
      formdata.append(`ornaments[${index}][net_weight]`, ornament.net_weight?.toString());
      formdata.append(`ornaments[${index}][wastage]`, ornament.wastage?.toString() || '');
      formdata.append(`ornaments[${index}][amount]`, ornament.amount?.toString() || '');
    });
    const resp = await API.patch(
      `${estimation.requestSales}/${estimationId}/sales_approval`,
      formdata,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

// request sales negotiation
export const requestSalesNegotiation = async (
  esimationData: Estimation,
  transactionId: string,
  estimationId: string
) => {
  try {
    const formdata = new FormData();
    formdata.append(`customer_expected_amount`, esimationData.customer_expected_amount?.toString());
    formdata.append(`transaction_id`, transactionId);
    formdata.append(`final_amount`, esimationData.final_amount?.toString());
    formdata.append(`service_charge_amount`, esimationData.service_charge_amount?.toString());
    formdata.append(`service_charge`, esimationData.service_charge?.toString());

    // gold rates
    // formdata.append(`margin_24k`, esimationData.margin_24k?.toString() || '');
    // formdata.append(`margin_22k`, esimationData.margin_22k?.toString() || '');
    // formdata.append(`rate_17k_21k`, esimationData.rate_17k_21k?.toString() || '');
    // formdata.append(`rate_14k_17k`, esimationData.rate_14k_17k?.toString() || '');

    if (esimationData.deleted_ids?.length) {
      esimationData.deleted_ids.forEach((item) => {
        formdata.append(`deleted_ids[]`, item.toString());
      });
    }

    // ornaments
    esimationData.ornaments.forEach((ornament, index) => {
      formdata.append(`ornaments[${index}][id]`, ornament.id || '');
      formdata.append(`ornaments[${index}][name]`, ornament.ornament_name);
      formdata.append(`ornaments[${index}][ornament_image]`, ornament.ornament_image);
      formdata.append(`ornaments[${index}][weight_image]`, ornament.weight_image);
      formdata.append(`ornaments[${index}][purity_image]`, ornament.purity_image);
      formdata.append(`ornaments[${index}][gold_rate]`, ornament.gold_rate?.toString() || '');
      formdata.append(`ornaments[${index}][gross_weight]`, ornament.gross_weight?.toString() || '');
      formdata.append(`ornaments[${index}][net_weight]`, ornament.net_weight?.toString());
      formdata.append(`ornaments[${index}][purity]`, ornament.purity?.toString());
      formdata.append(`ornaments[${index}][stone_weight]`, ornament.stone_weight?.toString());
      formdata.append(`ornaments[${index}][wastage]`, ornament.wastage?.toString() || '');
      formdata.append(`ornaments[${index}][branch_purity]`, ornament?.branch_purity);
      formdata.append(`ornaments[${index}][purity_value]`, ornament.purity_value?.toString());
      formdata.append(`ornaments[${index}][amount]`, ornament.amount?.toString() || '');
    });

    const resp = await API.patch(
      `${estimation.requestSales}/${estimationId}/request_negotiation`,
      formdata,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

// Forward to KYC team
export const forwardToKYC = async (
  esimationData: Estimation,
  transactionId: string,
  estimationId: string
) => {
  try {
    const formdata = new FormData();
    formdata.append(`customer_expected_amount`, esimationData.customer_expected_amount?.toString());
    formdata.append(`transaction_id`, transactionId);
    formdata.append(`final_amount`, esimationData.final_amount?.toString());
    formdata.append(`service_charge_amount`, esimationData.service_charge_amount?.toString());
    formdata.append(`service_charge`, esimationData.service_charge?.toString());

    // TODO: Might be needed during the 3 days KYC-pending
    // gold rates
    // formdata.append(`margin_24k`, esimationData.margin_24k?.toString() || '');
    // formdata.append(`margin_22k`, esimationData.margin_22k?.toString() || '');
    // formdata.append(`rate_17k_21k`, esimationData.rate_17k_21k?.toString() || '');
    // formdata.append(`rate_14k_17k`, esimationData.rate_14k_17k?.toString() || '');

    if (esimationData.deleted_ids?.length) {
      esimationData.deleted_ids.forEach((item) => {
        formdata.append(`deleted_ids[]`, item.toString());
      });
    }

    // ornaments
    esimationData.ornaments.forEach((ornament, index) => {
      formdata.append(`ornaments[${index}][id]`, ornament.id || '');
      formdata.append(`ornaments[${index}][name]`, ornament.ornament_name);
      formdata.append(`ornaments[${index}][ornament_image]`, ornament.ornament_image);
      formdata.append(`ornaments[${index}][weight_image]`, ornament.weight_image);
      formdata.append(`ornaments[${index}][purity_image]`, ornament.purity_image);
      formdata.append(`ornaments[${index}][gold_rate]`, ornament.gold_rate?.toString() || '');
      formdata.append(`ornaments[${index}][gross_weight]`, ornament.gross_weight?.toString() || '');
      formdata.append(`ornaments[${index}][net_weight]`, ornament.net_weight?.toString());
      formdata.append(`ornaments[${index}][purity]`, ornament.purity?.toString());
      formdata.append(`ornaments[${index}][stone_weight]`, ornament.stone_weight?.toString());
      formdata.append(`ornaments[${index}][wastage]`, ornament.wastage?.toString() || '');
      formdata.append(`ornaments[${index}][branch_purity]`, ornament?.branch_purity);
      formdata.append(`ornaments[${index}][purity_value]`, ornament.purity_value?.toString());
      formdata.append(`ornaments[${index}][amount]`, ornament.amount?.toString() || '');
    });

    const resp = await API.patch(`${estimation.branchKYC}/${estimationId}/branch_kyc`, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

// request sales head approvals
export const requestSalesHead = async (
  esimationData: Estimation,
  transactionId: string,
  estimationId: string
) => {
  try {
    const formdata = new FormData();
    formdata.append(`customer_expected_amount`, esimationData.customer_expected_amount?.toString());
    formdata.append(`transaction_id`, transactionId);
    formdata.append(`final_amount`, esimationData.final_amount?.toString());
    formdata.append(`service_charge_amount`, esimationData.service_charge_amount?.toString());
    formdata.append(`service_charge`, esimationData.service_charge?.toString());

    formdata.append(`updated_margin_24k`, esimationData.updated_margin_24k?.toString() || '');
    formdata.append(`updated_margin_22k`, esimationData.updated_margin_22k?.toString() || '');
    formdata.append(`updated_rate_17k_21k`, esimationData.updated_rate_17k_21k?.toString() || '');
    formdata.append(`updated_rate_14k_17k`, esimationData.updated_rate_14k_17k?.toString() || '');
    formdata.append(`is_rates_updated`, esimationData.is_rates_updated?.toString() || 'false');

    // ornaments
    esimationData.ornaments.forEach((ornament, index) => {
      formdata.append(`ornaments[${index}][id]`, ornament.id || '');
      formdata.append(`ornaments[${index}][name]`, ornament.ornament_name);
      formdata.append(`ornaments[${index}][ornament_image]`, ornament.ornament_image);
      formdata.append(`ornaments[${index}][weight_image]`, ornament.weight_image);
      formdata.append(`ornaments[${index}][purity_image]`, ornament.purity_image);
      formdata.append(`ornaments[${index}][gold_rate]`, ornament.gold_rate?.toString() || '');
      formdata.append(`ornaments[${index}][gross_weight]`, ornament.gross_weight?.toString() || '');
      formdata.append(`ornaments[${index}][net_weight]`, ornament.net_weight?.toString());
      formdata.append(`ornaments[${index}][purity]`, ornament.purity?.toString());
      formdata.append(`ornaments[${index}][stone_weight]`, ornament.stone_weight?.toString());
      formdata.append(`ornaments[${index}][wastage]`, ornament.wastage?.toString() || '');
      formdata.append(`ornaments[${index}][branch_purity]`, ornament?.branch_purity);
      formdata.append(`ornaments[${index}][purity_value]`, ornament.purity_value?.toString());
      formdata.append(`ornaments[${index}][amount]`, ornament.amount?.toString() || '');
    });
    const resp = await API.patch(
      `${estimation.requestSales}/${estimationId}/request_sales_head`,
      formdata,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const salesNegotiationApproval = async (
  esimationData: Estimation,
  transactionId: string,
  rejected: boolean,
  estimationId: string
) => {
  try {
    const formdata = new FormData();
    formdata.append(`transaction_id`, transactionId);
    formdata.append(`final_amount`, esimationData.final_amount?.toString());
    formdata.append(`service_charge_amount`, esimationData.service_charge_amount?.toString());
    formdata.append(`service_charge`, esimationData.service_charge?.toString());
    formdata.append(`rejected`, rejected.toString());

    if (rejected) {
      formdata.append(
        `remark`,
        esimationData.reject_reason && esimationData.reject_reason !== 'Others'
          ? esimationData.reject_reason
          : esimationData.other_reason || ''
      );
    } else {
      formdata.append(`remark`, esimationData.sales_remark?.toString() || '');
    }

    formdata.append(`updated_margin_24k`, esimationData.updated_margin_24k?.toString() || '');
    formdata.append(`updated_margin_22k`, esimationData.updated_margin_22k?.toString() || '');
    formdata.append(`updated_rate_17k_21k`, esimationData.updated_rate_17k_21k?.toString() || '');
    formdata.append(`updated_rate_14k_17k`, esimationData.updated_rate_14k_17k?.toString() || '');
    formdata.append(`is_rates_updated`, esimationData.is_rates_updated?.toString() || 'false');

    // ornaments
    esimationData.ornaments.forEach((ornament, index) => {
      formdata.append(`ornaments[${index}][id]`, ornament.id || '');
      formdata.append(`ornaments[${index}][name]`, ornament.ornament_name);
      formdata.append(`ornaments[${index}][ornament_image]`, ornament.ornament_image);
      formdata.append(`ornaments[${index}][gold_rate]`, ornament.gold_rate?.toString() || '');
      formdata.append(`ornaments[${index}][net_weight]`, ornament.net_weight?.toString());
      formdata.append(`ornaments[${index}][wastage]`, ornament.wastage?.toString() || '');
      formdata.append(`ornaments[${index}][amount]`, ornament.amount?.toString() || '');
    });
    const resp = await API.patch(
      `${estimation.requestSales}/${estimationId}/negotiation_approval`,
      formdata,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const salesHeadApproval = async (
  esimationData: Estimation,
  transactionId: string,
  rejected: boolean,
  estimationId: string
) => {
  try {
    const formdata = new FormData();
    formdata.append(`transaction_id`, transactionId);
    formdata.append(`final_amount`, esimationData.final_amount?.toString());
    formdata.append(`service_charge_amount`, esimationData.service_charge_amount?.toString());
    formdata.append(`service_charge`, esimationData.service_charge?.toString());
    formdata.append(`rejected`, rejected.toString());
    formdata.append(`remark`, esimationData.sales_head_remark?.toString() || '');

    formdata.append(`updated_margin_24k`, esimationData.updated_margin_24k?.toString() || '');
    formdata.append(`updated_margin_22k`, esimationData.updated_margin_22k?.toString() || '');
    formdata.append(`updated_rate_17k_21k`, esimationData.updated_rate_17k_21k?.toString() || '');
    formdata.append(`updated_rate_14k_17k`, esimationData.updated_rate_14k_17k?.toString() || '');
    formdata.append(`is_rates_updated`, esimationData.is_rates_updated?.toString() || '');

    // ornaments
    esimationData.ornaments.forEach((ornament, index) => {
      formdata.append(`ornaments[${index}][id]`, ornament.id || '');
      formdata.append(`ornaments[${index}][name]`, ornament.ornament_name);
      formdata.append(`ornaments[${index}][ornament_image]`, ornament.ornament_image);
      formdata.append(`ornaments[${index}][weight_image]`, ornament.weight_image);
      formdata.append(`ornaments[${index}][purity_image]`, ornament.purity_image);
      formdata.append(`ornaments[${index}][gold_rate]`, ornament.gold_rate?.toString() || '');
      formdata.append(`ornaments[${index}][net_weight]`, ornament.net_weight?.toString());
      formdata.append(`ornaments[${index}][wastage]`, ornament.wastage?.toString() || '');
      formdata.append(`ornaments[${index}][amount]`, ornament.amount?.toString() || '');
    });
    const resp = await API.patch(
      `${estimation.postGoldRateApprovals}/${estimationId}/gold_rate_approval`,
      formdata,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
