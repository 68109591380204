import OneSignal from 'react-onesignal';
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import useOneSignal from 'src/hooks/use-onesignal';

import API from 'src/apis';
import cookies from 'src/apis/cookies';
import { RootState, AppDispatch } from 'src/store/store';
import { getUserInfo } from 'src/store/slices/user-slice';
import { getNotificationsCount } from 'src/store/notifications-slice';

import { SplashScreen } from 'src/components/loading-screen';

const loginPaths: Record<string, string> = {
  jwt: paths.auth.login,
};

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const { loading } = useSelector((store: RootState) => store.user);
  return <>{loading ? <SplashScreen /> : <Container>{children}</Container>}</>;
}

function Container({ children }: Props) {
  const router = useRouter();

  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((store: RootState) => store.user);

  // Redirects to login
  const loginRedirect = () => {
    const loginPath = loginPaths.jwt;
    const href = `${loginPath}`;
    router.replace(href);
  };

  // Auth ch
  useOneSignal();

  const check = useCallback(async () => {
    const token = cookies.getCookies('wg-token');
    try {
      if (!token) {
        loginRedirect();
      } else {
        API.setAccessToken(token);
        const response = await dispatch(getUserInfo()).unwrap();
        await dispatch(getNotificationsCount());
        await OneSignal.login(response.id);
      }
    } catch (error) {
      cookies.removeCookie('wg-token');
      loginRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return null;
  }

  return <>{children}</>;
}
