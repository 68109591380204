import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

// interfaces
import { User, Login } from 'src/interfaces';
import { authMe, authenticate } from 'src/apis/authentication';

interface State {
  user: User | null;
  loading: boolean;
  error: boolean;
}

const initialState: State = {
  user: null,
  loading: false,
  error: false,
};

// create users
/* eslint-disable no-useless-catch */
export const loginUser = createAsyncThunk(
  'user/login',
  async ({ employee_id, password }: Login) => {
    try {
      const user = await authenticate(employee_id, password);
      return user;
    } catch (error) {
      throw error;
    }
  }
);

export const getUserInfo = createAsyncThunk('user/me', async (_, { rejectWithValue }) => {
  try {
    const user = await authMe();
    return user;
  } catch (error) {
    // Return a rejected action with the error payload
    return rejectWithValue(error);
  }
});

// Redux slice
const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    setCurrentUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getUserInfo.pending, (state) => {
        if (!state.user) {
          state.loading = true;
        }
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { setCurrentUser } = userSlice.actions;
export default userSlice.reducer;
